import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';


export const initialState = fromJS({
  isLoading: false,
  content: null,
});

const reducer = prepareReducers(
  {
    [actions.getContentAction]: (state) => state
      .set('isLoading', true),
    [actions.getContentSuccessAction]: (state, {payload}) => state
      .set('isLoading', false)
      .set('content', payload),
    [actions.getContentFailedAction]: (state) => state
      .set('isLoading', false),
    [actions.clearContentAction]: (state) => state
      .set('isLoading', false)
      .set('content', null),
    [actions.bookmarkContentAction]: state => 
      state.update('content', (content) => content ? ({...content, favorite: !content.favorite}) : null)
  },
  initialState
);

export default reducer;
