import styled from 'styled-components';
import WrapperSource from '@dev7/pegasus-peg-ui/Wrapper';
import LinkSource from '@dev7/pegasus-peg-ui/Link';
import applyStyles from '@/app/styles/applyStyles';

export const MainMenuContainer = styled(WrapperSource)``;

export const Link = styled(LinkSource)`
  margin: 0 24px 0 0;
  vertical-align: middle;
  padding: ${({isButton}) => (isButton ? '12px 16px' : '0')};
  ${({isButton}) =>
    isButton &&
    `
    height: 40px;
    align-items: center;
  `}
  transition: all 0.3s ease-in-out;

  &:last-child {
    margin-right: 0;
  }

  ${applyStyles({section: 'MainMenu', component: 'Link'})}
  ${({isButton}) => isButton && applyStyles({section: 'MainMenu', component: 'LinkButton'})}
`;
