import { isBrowser, isAndroid, isIOS } from 'react-device-detect';

const devices = { android: 'Android Games', ios: 'iOS Games', browser: 'Browser Games' };

// const game_cotnent = [
//   {
//     id: 1,
//     name: 'Android Games'
//   },
//   {
//     id: 2,
//     name: 'iOS Games'
//   },
//   {
//     id: 3,
//     name: 'Browser Games'
//   },
// ]

export const checkDevice = (game_contents = []) => {
  // if game_contents Is empty return null as it is not defined what devices are available
  if (!game_contents.length) return null;

  for (let index = 0; index < game_contents.length; index++) {
    const element = game_contents[index];

    // if (element.hasOwnProperty('name') && element.hasOwnProperty('id')) {
      if (isAndroid && element.name === devices.android) {
        return element.id;
      }
      // schow browser content to ios device
      if (isIOS && element.name === devices.browser) {
        return element.id;
      }
      if (isBrowser && element.name === devices.browser) {
        return element.id;
      }
    // }
  }

  return null;
};
