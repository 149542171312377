import {prepareActions} from '@/app/utils/redux';


export const actions = prepareActions({},
  ['getFavourites', true, true],
  ['favourites', true, true],
  ['unfavourites', true, true],
);

export default actions;
