import styled, {keyframes} from 'styled-components';
import {ReactComponent as AvatarSvg} from './svg/Avatar.svg';
import Paragraph from '@dev7/pegasus-peg-ui/Paragraph';

const shake = keyframes`
0%, 100% {
  transform: translateX(0);
}

10%, 30%, 50%, 70%, 90% {
  transform: translateX(-10px);
}
20%, 40%, 60%, 80% {
  transform: translateX(10px);
}
`;

export const Button = styled.span`
  height: 65px;
  width: 65px;
  position: fixed;
  bottom: ${props => (props.isHidden ? '-120px' : '20px')};
  right: 20px;
  z-index: 2147483640;
  background: linear-gradient(135deg, #f3b112 0%, #f36d12 100%);
  transition: all ease 1s;
  border-radius: 50%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: ${shake};

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
  }
`;

export const ChatBubble = styled('div')`
  background: #ffffff;
  border-radius: 7px 7px 7px 0;
  width: 31px;
  height: 31px;
  position: absolute;
  top: 17px;
  left: 17px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
`;
export const ChatBubbleTriangle = styled('div')`
  border-color: transparent transparent transparent #ffffff;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px;
  left: 17px;
  bottom: 12px;
`;

export const ChatBubbleContent = styled('div')`
  transform-origin: 50% 50%;
  width: 31px;
  height: 5px;
  position: absolute;
  top: 31px;
  left: 17px;
  overflow: hidden;
  transform: rotate(0deg);
  transition: all ease 0.2s;
  ${Button}:hover & {
    transform: rotate(-45deg);
  }
`;

export const ChatDot = styled('div')`
  width: 4px;
  height: 4px;
  top: 0;
  background: #f39c12;
  border-radius: 50%;
  position: absolute;
  transition: all ease 0.2s;
`;
export const ChatDot1 = styled(ChatDot)`
  left: 7px;
  ${Button}:hover & {
    border-radius: 0;
    width: 0;
    height: 0;
    background: transparent;
    border-style: solid;
    border-width: 2.5px 4px 2.5px 0;
    border-color: transparent #f39c12 transparent transparent;
    left: 3px;
  }
`;
export const ChatDot2 = styled(ChatDot)`
  left: 14px;
  ${Button}:hover & {
    border-radius: 0;
    width: 14px;
    left: 8px;
    height: 5px;
  }
`;
export const ChatDot3 = styled(ChatDot)`
  left: 21px;
  ${Button}:hover & {
    border-radius: 0 1px 1px 0;
    width: 4px;
    height: 5px;
    left: 23px;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: ${props => (props.isOpen ? '0' : '-442px')};
  right: 10px;
  height: 442px;
  max-height: calc(100% - 70px);
  width: 334px;
  max-width: calc(100% - 40px);
  background: linear-gradient(45deg, #4b1248 0%, #f0c27b 100%);
  border-bottom: none;
  z-index: 2147483640;
  transition: bottom 0.3s;
  border-radius: 7px 7px 0px 0px;
`;

export const Avatar = styled(AvatarSvg)`
  height: 20px;
  width: 20px;
`;

export const ContentHeader = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid #dfc8b8;
`;


export const ButtonHide = styled.span`
  position: absolute;
  display: inline-block;
  top: 7px;
  right: 10px;
  opacity: 0.7;
  transition: 0.2s ease opacity;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:after {
    content: '';
    height: 2px;
    width: 10px;
    background-color: #ffffff;
    display: inline-block;
  }
`;

export const Title = styled.p`
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  margin-left: 8px;
`;

export const Messages = styled.div`
  padding: 26px 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonStart = styled.button`
  width: 340px;
  max-width: 100%;
  padding: 12px 34px;
  border-radius: 22px;
  font-size: 1.3rem;
  color: #000;
  background: #fff;
  border: 0px;
  transition: background ease 0.2s;
  box-shadow: 0 4px 5px rgb(0 0 0 / 20%);
  &:hover {
    cursor: pointer;
    background: #ecc781;
  }
  ${props =>
    props.disabled
      ? `
  background: #BABABB
  &:hover {
    cursor: not-allowed
    background: #BABABB
  }

  `
      : ``}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Label = styled.label`
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  padding-left: 18px;
  color: #fff;
`;

export const InputWrapper = styled.div`
  position: relative;
  & label > span {
    top: 0px;
  }
  & input:checked + span {
    background-color: #ecc781;
    &:after {
      border-color: #000;
    }
  }
`;

export const Input = styled.input`
  position: absolute;
  margin-right: 8px;
  top: 6px;
  border: solid 1px red;
`;

export const Link = styled.a`
  color: #fff;
  transition: 0.3s ease color;
  &:hover {
    color: #ecc781;
  }
`;

export const StyledInputText = styled('span')`
  color: #fff;
  font-weight: 500;
`;

export const Footer = styled(Paragraph)`
  color: #fff;
  font-weight: 500;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 24px 20px;
`;
