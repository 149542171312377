import axios from 'axios';
import CONFIG from '@/app/config';
import {getUrlWithParams} from '@/app/utils/query';

const CONTENTS_URL = `${CONFIG.api.appUrl}/contents`;

export const getPremium = async (params) => {
  const url = getUrlWithParams(`${CONTENTS_URL}/premium`, params);

  const response = await axios.get(url);
  return response.data;
};
