import styled from 'styled-components';
import config from '@/product/config.json';
import applyStyles from '@/app/styles/applyStyles';
import Cookies from '@dev7/pegasus-peg-ui/Cookies';
import ParagraphSource from '@dev7/pegasus-peg-ui/Paragraph';
import LinkSource from '@dev7/pegasus-peg-ui/Link';

export const CookiesStyled = styled(Cookies)`
  border-color: ${({theme}) =>
    theme.COOKIES.borderColor || config.config.STYLEGUIDE.colors.lighterBorder
      ? config.config.STYLEGUIDE.colors.lighterBorder
      : config.config.STYLEGUIDE.colors.light};
  z-index: 2147483640;
  @media screen and (min-width: 768px) {
    width: 400px;
    right: 32px;
    bottom: ${(props) => (props.isChatOpen ? '455px' : '96px')};
    padding-left: 24px;
    transition: bottom 0.2s;
  }
`;

export const Link = styled(LinkSource)`
  color: ${({theme}) => theme && theme.COOKIES.LINK.color};
  font-weight: ${({theme}) => theme.COOKIES.LINK.fontWeight || '600'};
  line-height: ${({theme}) => theme.PARAGRAPH.types.p3.lineHeight || theme.COOKIES.LINK.lineHeight};
  font-size: ${({theme}) => theme.PARAGRAPH.types.p3.fontSize || theme.COOKIES.LINK.fontSize};
  ${applyStyles({section: 'CookiesModal', component: 'Link'})}
`;

export const Paragraph = styled(ParagraphSource)``;
