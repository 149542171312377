import { Component } from 'react';
import {getQueryParams} from '@/app/utils/query';
import {ROUTES} from '@/app//Routes';
import CONFIG from '@/app/config';
import {
  BackdropMenu,
  Hamburger,
  LoggedWrapper,
  LogoLinkStyled,
  LogoStyled,
  MainMenu,
  Searcher,
  UserMenu,
  CoinsLink,
  CoinsGamingIcon,
} from './Logged.styled';

const MIN_SEARCH_LENGTH = 2;

class Logged extends Component {
  constructor() {
    super();
    const { q: searchPhrase } = getQueryParams();
    this.state = {
      searchPhrase: searchPhrase || '',
      isOpen: false,
      searcherExpanaded: false,
    };
  }

  componentDidUpdate(prevProps) {
    this.updateSearchPhrase();
    this.updateOpenState(prevProps);
  }

  updateOpenState = prevProps => {
    const { isOpen, searchPhrase } = this.state;
    if (!isOpen) return;

    const { location } = this.props;
    const { q: query } = getQueryParams();

    const isDifferentPath = prevProps.location.pathname !== location.pathname;
    const isDifferentPhrase = query !== (searchPhrase || undefined);

    if (isDifferentPath || isDifferentPhrase) {
      this.setState({ isOpen: false });
    }
  };

  updateSearchPhrase = () => {
    let { q: searchPhrase } = getQueryParams();
    if (searchPhrase !== this.state.searchPhrase) {
      this.setState({ searchPhrase });
    }
  };

  handleSearch = phrase => {
    if (/\S/.test(phrase) && phrase.trim().length >= MIN_SEARCH_LENGTH) {
      this.props.history.push({
        pathname: ROUTES.SEARCH.path,
        search: `?q=${phrase}`,
      });
    }
  };

  handleLinkClick = path => {
    if (path !== this.props.location.pathname) {
      this.props.history.push(path);
    }
  };

  handleHamburgerClick = () => {
    this.setState(prev => ({ isOpen: !prev.isOpen }));
  };

  handleCollapse = () => {
    setTimeout(() => {
      this.setState(prev => ({ searcherExpanaded: !prev.searcherExpanaded }));
    }, 200);
  };

  render() {
    const { t, user, contentLink, location, logout } = this.props;
    const { searchPhrase, searcherExpanaded, isOpen } = this.state;
    const userCredits = contentLink ? contentLink.credits : user.credits;

    const backdropLinks = [
      {
        id: 1,
        type: 'navlink',
        path: ROUTES.DISCOVER.path,
        text: t('Discover'),
        callback: () => this.handleLinkClick(ROUTES.DISCOVER.path),
      },
      {
        id: 2,
        type: 'navlink',
        path: ROUTES.BROWSE.path,
        text: t('Browse'),
        callback: () => this.handleLinkClick(ROUTES.BROWSE.path),
      },
      {
        id: 3,
        type: 'navlink',
        path: ROUTES.MY_LIST.path,
        text: t('My List'),
        callback: () => this.handleLinkClick(ROUTES.MY_LIST.path),
      },
      {
        id: 4,
        type: CONFIG.support_redirect ? 'anchor' : 'navlink',
        path: ROUTES.SUPPORT.path,
        text: t('Support'),
        callback: () => this.handleLinkClick(ROUTES.SUPPORT.path),
        isActive: location.pathname === ROUTES.SUPPORT.path,
      },
      {
        id: 5,
        type: 'navlink',
        path: ROUTES.ACCOUNT.path,
        text: t('Account'),
        callback: () => this.handleLinkClick(ROUTES.ACCOUNT.path),
      },
      { id: 6, type: 'fake', path: '', text: t('Logout'), callback: logout },
      {
        id: 7,
        type: 'fake',
        path: '',
        icon: <CoinsGamingIcon />,
        text: `${userCredits} / ${user.credits_limit} ${t('coins')}`,
      },
    ];

    const links = [
      { id: 1, type: 'navlink', path: ROUTES.DISCOVER.path, text: t('Discover') },
      { id: 2, type: 'navlink', path: ROUTES.BROWSE.path, text: t('Browse') },
      { id: 3, type: 'navlink', path: ROUTES.MY_LIST.path, text: t('My List') },
      {
        id: 4,
        type: CONFIG.support_redirect ? 'anchor' : 'navlink',
        path: ROUTES.SUPPORT.path,
        text: t('Support'),
        isActive: location.pathname === ROUTES.SUPPORT.path,
      },
    ];

    return (
      <LoggedWrapper>
        <Hamburger onClick={this.handleHamburgerClick} isActive={isOpen} />
        <LogoLinkStyled url={user ? ROUTES.DISCOVER.path : ROUTES.MAINPAGE.path} ariaLabel={t('Go to homepage')}>
          <LogoStyled />
        </LogoLinkStyled>
        <MainMenu links={links} />
        <CoinsLink type="fake" to="">
          <CoinsGamingIcon />
          {`${userCredits} / ${user.credits_limit} `}
          {t('coins')}
        </CoinsLink>
        <Searcher
          searcherExpanaded={searcherExpanaded}
          ariaLabel={t('Enter search phrase')}
          placeholder={t('Search')}
          location={location.pathname}
          onSearch={this.handleSearch}
          searchPhrase={searchPhrase}
          searchPath={ROUTES.SEARCH.path}
          collapse={this.handleCollapse}
          expandedReversed
        />
        <UserMenu />
        <BackdropMenu isOpen={isOpen} links={backdropLinks} />
      </LoggedWrapper>
    );
  }
}

export default Logged;
