import styled from 'styled-components';
import Wrapper from '@dev7/pegasus-peg-ui/Wrapper';
import Link from '@dev7/pegasus-peg-ui/Link';
import HamburgerSource from '@dev7/pegasus-peg-ui/Hamburger';
import BackdropMenuSource from '@dev7/pegasus-peg-ui/BackdropMenu';
import LanguagePickerSource from '@dev7/pegasus-peg-ui/LanguagePicker';
import media from '@/app/styles/media.styled';
import MainMenuSource from '../MainMenu/index';
import {getConfig} from '@/app/utils/general';
import applyStyles from '@/app/styles/applyStyles';
import {ReactComponent as Logo} from '@/product/assets/svg/logo.svg';

const config = getConfig();

export const LogoStyled = styled(Logo)`
  display: block;
  width: ${config.STYLEGUIDE.others.logoWidth || '120px'};
  max-width: 100%;
  g {
    ${applyStyles({section: 'Header', component: 'LogoSvg'})};
  }
`;

export const LogoLinkStyled = styled(Link)`
  display: block;
  width: ${config.STYLEGUIDE.others.logoWidth || '120px'};
  max-width: calc(100% - 40px - 16px);
  z-index: 2;
  ${media.md`
    flex-shrink: 0;
  `}
`;

export const NotLoggedWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const MenuWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
`;

export const Hamburger = styled(HamburgerSource)`
  flex-shrink: 0;
  margin-right: 16px;
  ${media.md`
    display: none;
  `}
`;

export const BackdropMenu = styled(BackdropMenuSource)`
  display: flex;
  justify-content: center;
  height: calc(100vh - 80px);
  top: 80px;
  z-index: 25;
  ${media.md`
    display: none;
  `};

  ul {
    width: 100%;
    max-width: 450px;
    margin: auto;
    li {
      text-align: center;
      padding: 24px 0;
      a {
        ${applyStyles({section: 'Header', component: 'BackdropMenuLink'})}
      }
    }
  }
`;

export const MainMenu = styled(MainMenuSource)`
  display: none;
  ${media.md`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 40px;
    margin-right: 16px;
    z-index: 1;
  `}
`;

export const LanguagePicker = styled(LanguagePickerSource)`
  margin-left: 16px;
  ${media.md`
    margin-left: 0;
  `}
`;
