import {MenuItemStyled, MenuListStyled} from './MenuList.styled';
import Link from '../../Link';
import IBackdropMenuTheme from 'libs/peg-ui/src/components/BackdropMenu/IBackdropMenuTheme';

enum LinkTypes {
  link = 'link',
  navlink = 'navlink',
  mail = 'mail',
  tel = 'tel',
  anchor = 'anchor',
  fake = 'fake',
}

interface ILinks {
  id: number;
  type: LinkTypes;
  path: string;
  text: string;
  callback: Function;
}

interface MenuListProps {
  links: Array<ILinks>;
  componentTheme: IBackdropMenuTheme;
}
function MenuList(props: MenuListProps) {
  const {links = [], componentTheme} = props;
  return (
    <MenuListStyled>
      {links.map((link: ILinks) => (
        <MenuItemStyled key={link.id} componentTheme={componentTheme}>
          <Link
            type={link.type}
            url={link.path}
            description={link.text}
            onClick={link.callback}
            componentTheme={componentTheme}
          />
        </MenuItemStyled>
      ))}
    </MenuListStyled>
  );

}

export default MenuList;
