/* eslint-disable @typescript-eslint/no-empty-function */
import {Component} from 'react';
import CONFIG from '@/app/config';
import LiveAgent from './LiveAgent';
import {checkIfCookieExists, setCookie} from '@/app/modules/cookies';
import {
  Wrapper,
  Content,
  Button,
  ButtonStart,
  ButtonWrapper,
  ButtonHide,
  Title,
  Avatar,
  ContentHeader,
  InputWrapper,
  ChatBubble,
  ChatBubbleTriangle,
  ChatBubbleContent,
  ChatDot1,
  ChatDot2,
  ChatDot3,
  Link,
  StyledInputText,
  Messages,
  Footer,
} from './FakeChat.styled.js';

import CheckboxField from '@dev7/pegasus-peg-ui/Form/CheckboxField';

const date = new Date(Date.now() + 3600000 * 24 * 14);
const cookieLiveAgent2Week = { name: 'la_cookie-accept', value: true, expires: date };
const cookieLiveAgentSession = { name: '__liveagent', value: true };
const checkIfOpenLiveAgent =
  checkIfCookieExists(cookieLiveAgentSession.name) || checkIfCookieExists(cookieLiveAgent2Week.name);
const cookieAgreement = { name: 'cookies_agreement', value: true };

class FakeChat extends Component {
  constructor() {
    super();

    this.state = {
      isOpenFakeChat: false,
      checked: true,
      isVisibleLiveAgent: checkIfOpenLiveAgent,
      isVisibleLiveAgentClicked: false,
    };
  }

  componentDidMount() {
    !this.props.hasFakeChat && this.setState({isVisibleLiveAgent: true});
  }

  toggleFakeChat = () => {
    this.props.onToggleFakeChat(!this.state.isOpenFakeChat);
    this.setState((prev) => ({isOpenFakeChat: !prev.isOpenFakeChat}));
  };

  toggleCheckboxState = () => {
    this.setState((prev) => ({checked: !prev.checked}));
  };

  handleStartChat = () => {
    this.setState(
      () => ({isOpenFakeChat: false, isVisibleLiveAgent: true, isVisibleLiveAgentClicked: true}),
      this.appendLiveAgent
    );
  };

  onAfterLoadLiveAgent = () => {
    if (typeof ResizeObserver === 'undefined') {
      console.warn('ResizeObserver is not supported by your browser.');
      return;
    }
    const ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentRect.height <= 64) {
          this.props.onToggleChat(false);
        } else {
          this.props.onToggleChat(true);
        }
      }
    });
    function onRemove(element, callback) {
      const parent = element.parentNode;
      if (!parent) throw new Error('The node must already be attached');
      const obs = new MutationObserver(mutations => {
        for (const mutation of mutations) {
          for (const el of mutation.removedNodes) {
            if (el === element) {
              obs.disconnect();
              callback();
            }
          }
        }
      });
      obs.observe(parent, {
        childList: true,
      });
    }

    const runningChatCookieArr = document.cookie.split(';').filter(item => item.includes('LaRunningChat'));
    const runningChatCookieVal = runningChatCookieArr.length !== 0 && runningChatCookieArr['0'].split('=')['1'];
    if (runningChatCookieVal) {
      this.props.onToggleChat(true);
      setTimeout(() => {
        const widget = window.LiveAgent.instance.openedWidget
        if(widget){
          const element = widget.element;
          ro.observe(element);
          onRemove(element, () => ro.unobserve(element));
        }
      }, 200);
    }

    window.agentButton &&
      window.agentButton.buttonDiv.addEventListener('click', () => {
        this.props.onToggleChat(true);
        document.querySelectorAll('.i-embedded-chat').forEach(item => {
          ro.observe(item);
          onRemove(item, () => ro.unobserve(item));
        });
      });
    window.agentButton.chat.addOnCloseFunction &&
      window.agentButton.chat.addOnCloseFunction(() => {
        this.props.onToggleChat(false);
      });
    document.querySelectorAll('.i-embedded-chat').forEach(item => {
      ro.observe(item);
      onRemove(item, () => ro.unobserve(item));
    });
  };

  // append live agent after accept
  appendLiveAgent = () => {
    checkIfCookieExists(cookieAgreement.name) ? setCookie(cookieLiveAgent2Week) : setCookie(cookieLiveAgentSession);
    this.props.onStartChat(true);
  };
  render() {
    const { isUS } = this.props;
    const {isOpenFakeChat, checked, isVisibleLiveAgent, isVisibleLiveAgentClicked} = this.state;
    return (
      <div>
        <div id="chatButton"></div>
        {(isVisibleLiveAgent || this.props.cookiesAccepted) && !this.props.test && CONFIG.live_agent && (
          <LiveAgent
            onAfterLoadLiveAgent={this.onAfterLoadLiveAgent}
            autoOpenChat={isVisibleLiveAgentClicked}
            id={CONFIG.live_agent}
          />
        )}
        {this.props.hasFakeChat && (
          <Button isHidden={isOpenFakeChat || isVisibleLiveAgent} onClick={this.toggleFakeChat}>
            <ChatBubble />
            <ChatBubbleTriangle />
            <ChatBubbleContent>
              <ChatDot1 />
              <ChatDot2 />
              <ChatDot3 />
            </ChatBubbleContent>
          </Button>
        )}
        {this.props.hasFakeChat && (
          <Wrapper isOpen={isOpenFakeChat}>
            <Content>
              <ContentHeader>
                <Avatar />
                <Title>LiveAgent</Title>
                <ButtonHide onClick={this.toggleFakeChat} />
              </ContentHeader>
              <Messages>
                <InputWrapper>
                  <CheckboxField
                    name="liveagent-checkbox"
                    id="liveagent-checkbox"
                    htmlFor="liveagent-checkbox"
                    label={
                      <StyledInputText>
                        {isUS
                          ? 'I agree to receive all the cookies sent by LiveAgent (Quality Unit, LLC) which is the provider of this chat. Full privacy is available under: '
                          : 'I agree to receive all the cookies sent by LiveAgent (Quality Unit, s. r. o.) which is the provider of this chat. Full privacy is available under '}
                        <Link href="https://www.ladesk.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
                          https://www.ladesk.com/privacy-policy/
                        </Link>
                      </StyledInputText>
                    }
                    checked={checked}
                    onChange={this.toggleCheckboxState}
                  />
                </InputWrapper>
                <ButtonWrapper>
                  <ButtonStart disabled={!checked} onClick={checked ? this.handleStartChat : null}>
                    Start chat
                  </ButtonStart>
                </ButtonWrapper>
              </Messages>
              <Footer pType="p4">
                If you have any questions, you can contact us at{' '}
                <Link
                  css={`
                    text-decoration: underline;
                    font-weight: 700;
                  `}
                  href="mailto:support@pickaflick.co"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  support@pickaflick.co
                </Link>
              </Footer>
            </Content>
          </Wrapper>
        )}
      </div>
    );
  }
}

FakeChat.defaultProps = {
  onToggleChat: () => {
  },
  onToggleFakeChat: () => {
  },
  onStartChat: () => {
  },
  test: false,
};

export default FakeChat;
