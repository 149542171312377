import {ComponentType} from 'react';
import Input from '../Input';
import FieldError from '../FieldError';
import {InputFieldStyled, Label} from './InputField.styled';
import InputFieldTheme from './IInputFieldTheme';

interface InputFieldProps {
  id: string;
  name: string;
  type: string;
  onChange: Function;
  error?: string | boolean;
  touched?: boolean;
  disabled?: boolean;
  placeholder?: string;
  noValidate?: boolean;
  label?: string;
  children?: ComponentType | HTMLElement;
  componentTheme: InputFieldTheme;
  theme: any;
}

function InputField(props: InputFieldProps) {
  const {
    id,
    placeholder,
    name,
    type = 'text',
    error,
    touched,
    disabled,
    label,
    children,
    noValidate = false,
    componentTheme,
    onChange,
    ...rest
  } = props;
  return (
    <InputFieldStyled {...rest}>
      <Input
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        error={error && touched}
        touched={touched}
        disabled={disabled}
        onChange={onChange}
        componentTheme={componentTheme}
      />
      {label && (
        <Label htmlFor={id} componentTheme={componentTheme}>
          {label}
        </Label>
      )}
      {error && touched && !noValidate && <FieldError componentTheme={componentTheme}>{error}</FieldError>}
      {children}
    </InputFieldStyled>
  );
}

export default InputField;
