import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions as contentActions } from '@/app/reducers/content';
import { actions as notificationActions } from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import * as contentApi from '@/app/api/content';
import { prepareError } from '@/app/utils/sagas';

const logger = Logger.getInstance('ContentSaga');

export function* getContentSaga({ payload }) {
  try {
    const result = yield call(contentApi.getContent, payload);

    yield put(contentActions.getContentSuccessAction(result));
  } catch (error) {
    yield put(contentActions.getContentFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* bookmarkContentSaga() {
  try {
    yield put(contentActions.bookmarkContentAction());
  } catch (error) {
    yield put(contentActions.getContentFailedAction());
  }
}

export default function* watchForPromoContents() {
  //prettier-ignore
  yield all([
    takeLatest(contentActions.getContentAction, getContentSaga),
  ]);
}
