import { Responsive, ResponsiveSection } from '../../../styles.interface';
import {config} from '@/product/config.json';

const PrivacyInfo: ResponsiveSection = {
  TermTitleParagraph: {
    xs: {
      'font-size': '13px',
      'font-weight': '400',
      'line-height': '20px',
    },
  },
  ExpandParagraph: {
    xs: {
      color: config.STYLEGUIDE.colors.white,
      'font-weight': '700',
      'font-size': '14px',
    },
  },
};

const Register: ResponsiveSection = {
  CreditCardBox: {
    xs: {
      background: config.STYLEGUIDE.colors.lighter,
      'border-color': config.STYLEGUIDE.colors.lighter,
      color: config.STYLEGUIDE.others.textColor,
    },
  },
  RegisterLink: {
    xs: {
      color: config.STYLEGUIDE.others.textColor,
      'font-size': '12px',
      'font-weight': '400',
    },
  },
  RegisterLabel: {
    xs: {
      color: config.STYLEGUIDE.others.textColor,
      'font-size': '12px',
    },
  },
};

const Header: ResponsiveSection = {
  BackdropMenuSmallLink: {
    xs: {
      'font-size': '16px',
      'line-height': '22px',
    },
  },
};

const SubscriptionForm: ResponsiveSection = {
  LinkStyled: {
    xs: {
      color: config.STYLEGUIDE.colors.white,
    },
  },
};

const Modal: ResponsiveSection = {
  CoinsBox: {
    xs: {
      background: config.STYLEGUIDE.colors.lighter,
    },
  },
};

const Sections: Responsive = {
  Header,
  PrivacyInfo,
  Register,
  SubscriptionForm,
  Modal,
};

export default Sections;
