import { createSelector } from 'reselect';

const selectors = {
  selectState: (state) => state.get('categories'),
  isLoading: () => createSelector(selectors.selectState, categories => categories.get('isLoading')),
  isError: () => createSelector(selectors.selectState, categories => categories.get('isError')),
  getCategories: () => createSelector(selectors.selectState, categories => categories.get('categories')),
};

export default selectors;
