// @flow
import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { actions as favouritesActions } from '@/app/reducers/favourites';
import { actions as notificationActions } from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import * as favouritesApi from '@/app/api/favourites';
import { prepareError } from '@/app/utils/sagas';
import { bookmarkContentSaga } from '@/app/sagas/content';
import { checkDevice } from '@/app/utils/checkDevice';
import { getContentTypesSaga } from './contentTypes';

const logger = Logger.getInstance('FavouritesSaga');

export function* getFavouritesSaga() {
  try {
    yield call(getContentTypesSaga, { payload: null });

    const content_types = yield select(state => state.get('content_types').get('content_types'));
    if (content_types && content_types.length === 0) return;

    const contentTypeId = checkDevice(content_types);

    if (contentTypeId === null) return;

    const result = yield call(favouritesApi.getFavourites, { content_type_id: contentTypeId });

    yield put(favouritesActions.getFavouritesSuccessAction(result));
  } catch (error) {
    yield put(favouritesActions.getFavouritesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* favouritesSaga({ payload }) {
  try {
    const result = yield call(favouritesApi.favourites, payload.id);

    const favoritiesArr = yield select(state => state.get('favourites').get('contents'));

    yield put(favouritesActions.favouritesSuccessAction([...favoritiesArr, result]));
    payload.get === 'content'
      ? yield call(bookmarkContentSaga)
      : yield call(getFavouritesSaga, { payload: { screens: '1' } });
  } catch (error) {
    yield put(favouritesActions.favouritesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* unfavouritesSaga({ payload }) {
  try {
    const result = yield call(favouritesApi.unfavourites, payload.id);
    const favoritiesArr = yield select(state => state.get('favourites').get('contents'));
    let resultArr = [];
    if (favoritiesArr.length) {
      resultArr = favoritiesArr.filter(item => item.id !== result.id);
    }

    yield put(favouritesActions.unfavouritesSuccessAction(resultArr));
    payload.get === 'content'
      ? yield call(bookmarkContentSaga)
      : yield call(getFavouritesSaga, { payload: { screens: '1' } });
  } catch (error) {
    yield put(favouritesActions.unfavouritesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForFavourites() {
  yield all([
    takeLatest(favouritesActions.getFavouritesAction, getFavouritesSaga),
    takeEvery(favouritesActions.favouritesAction, favouritesSaga),
    takeEvery(favouritesActions.unfavouritesAction, unfavouritesSaga),
  ]);
}
