import styled, {css} from 'styled-components';
import config from '@/product/config.json';
import applyStyles from '@/app/styles/applyStyles';
import Cookies from '@dev7/pegasus-peg-ui/Cookies';
import TitleSource from '@dev7/pegasus-peg-ui/Title';
import ParagraphSource from '@dev7/pegasus-peg-ui/Paragraph';
import LinkSource from '@dev7/pegasus-peg-ui/Link';
import WrapperSource from '@dev7/pegasus-peg-ui/Wrapper';
import CheckmarkSource from '@dev7/pegasus-peg-ui/Checkmark';

export const CookieBackdrop = styled(WrapperSource)`
  ${({isExpanded}) =>
    isExpanded
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          z-index: 99;
          overflow-y: scroll;
        `
      : ''};
  ${({isExpanded}) =>
    isExpanded
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

export const CookiesSmallStyled = styled(Cookies)`
  border-color: ${({theme}) =>
    theme.COOKIES.borderColor || config.config.STYLEGUIDE.colors.lighterBorder
      ? config.config.STYLEGUIDE.colors.lighterBorder
      : config.config.STYLEGUIDE.colors.light};
  z-index: 2147483640;
  @media screen and (min-width: 768px) {
    width: 400px;
    right: 32px;
    bottom: ${props => (props.isFakeChatOpen ? '455px' : '100px')};
    padding-left: 24px;
    transition: bottom 0.2s;
  }
  ${({isVisible, isExpanded}) =>
    isVisible && !isExpanded
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
`;

export const CookiesStyled = styled(Cookies)`
  border-color: ${({theme}) =>
    theme.COOKIES.borderColor || config.config.STYLEGUIDE.colors.lighterBorder
      ? config.config.STYLEGUIDE.colors.lighterBorder
      : config.config.STYLEGUIDE.colors.light};
  z-index: 2147483640;
  position: relative;
  padding: 24px 32px 36px;
  height: min-content;
  right: unset;
  bottom: auto;
  top: 0%;
  margin: 36px 0;

  @media screen and (min-width: 768px) {
    width: 730px;
    bottom: auto;
  }
`;

export const Link = styled(LinkSource)`
  color: ${({theme}) => theme && theme.COOKIES.LINK.color};
  font-weight: ${({theme}) => theme.COOKIES.LINK.fontWeight || '600'};
  line-height: ${({theme}) => theme.PARAGRAPH.types.p3.lineHeight || theme.COOKIES.LINK.lineHeight};
  font-size: ${({theme}) => theme.PARAGRAPH.types.p3.fontSize || theme.COOKIES.LINK.fontSize};
  transition: all 0.2s ease-in-out;
  ${applyStyles({section: 'CookiesModal', component: 'Link'})}
`;

export const Title = styled(TitleSource)`
  margin-bottom: 14px;
`;

export const Paragraph = styled(ParagraphSource)``;

export const Wrapper = styled(WrapperSource)`
  height: auto;
  transition: all 0.4s;
  overflow: hidden;
`;

export const SelectAllWrapper = styled(WrapperSource)`
  position: relative;
  padding-left: 28px;
  margin: 25px 0 18px;
  cursor: pointer;
`;
export const Checkmark = styled(CheckmarkSource)`
  position: absolute;
  top: 3px;
  left: 0;
  margin: 0;
  cursor: pointer;
`;

export const NecessaryWrapper = styled(WrapperSource)`
  margin-bottom: 21px;
  padding-left: 28px;
`;

export const CheckboxField = styled(WrapperSource)`
  position: relative;
  margin-bottom: 21px;
  padding-left: 28px;
  cursor: pointer;
`;
