import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  contents: [],
  total: null,
  searchContents: [],
  searchTotal: null,
});

const reducer = prepareReducers(
  {
    [actions.getContentsAction]: (state) => state
      .set('isLoading', true),
    [actions.getContentsSuccessAction]: (state, {payload}) => {
      if(payload.searchParams && payload.searchParams.offset > 1) {
        return state
          .set('isLoading', false)
          .update('contents', arr => arr.concat(payload.contents))
          .set('total', payload.total)
      }
      return state
        .set('isLoading', false)
        .set('contents', payload.contents)
        .set('total', payload.total)
    },
    [actions.getContentsFailedAction]: (state) => state
      .set('isLoading', false),
    //browseGames
    [actions.getBrowseContentsAction]: (state) => state
      .set('isLoading', true),
    [actions.getBrowseContentsSuccessAction]: (state, {payload}) => {
      if(payload.searchParams && payload.searchParams.offset > 1) {
        return state
          .set('isLoading', false)
          .update('browseContents', arr => arr.concat(payload.contents))
          .set('browseTotal', payload.total)
      }
      return state
        .set('isLoading', false)
        .set('browseContents', payload.contents)
        .set('browseTotal', payload.total)
    },
    [actions.getBrowseContentsFailedAction]: (state) => state
      .set('isLoading', false),
    //searchGames
    [actions.getSearchContentsAction]: (state) => state
      .set('isLoading', true),
    [actions.getSearchContentsSuccessAction]: (state, {payload}) => state
      .set('isLoading', false)
      .set('searchContents', payload.contents)
      .set('searchTotal', payload.total),
    [actions.getSearchContentsFailedAction]: (state) => state
      .set('isLoading', false),
  },
  initialState
);

export default reducer;
