import {ReactNode} from 'react';
import {CheckmarkActive, CheckmarkChecked, CheckmarkPrimary} from './Checkmark.styled';
import {CheckmarkSquareChecked, CheckmarkSquarePrimary} from './CheckmarkSquare.styled';
import ICheckmarkTheme from './ICheckmarkTheme';

interface CheckmarkProps {
  visited?: boolean;
  active?: boolean;
  square?: boolean;
  children?: any;
  componentTheme: ICheckmarkTheme;
}

const Checkmark: React.SFC<CheckmarkProps> = ({ visited, active, square, children, componentTheme, ...rest }) => {
  const renderCheck = () => {
    if (active) {
      return <CheckmarkActive componentTheme={componentTheme} {...rest} />;
    } else if (visited) {
      return square ? (
        <CheckmarkSquareChecked componentTheme={componentTheme} {...rest} />
      ) : (
        <CheckmarkChecked componentTheme={componentTheme} {...rest} />
      );
    }
    return square ? (
      <CheckmarkSquarePrimary componentTheme={componentTheme} {...rest} />
    ) : (
      <CheckmarkPrimary componentTheme={componentTheme} {...rest} />
    );
  };

  return renderCheck();
};

export default Checkmark;