import styled from 'styled-components';
import IBackdropMenuTheme from 'libs/peg-ui/src/components/BackdropMenu/IBackdropMenuTheme';
//import Link from '../../Link';

export const MenuListStyled = styled.ul`
  display: flex;
  z-index: 26;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
interface MenuItemStyledProps {
  componentTheme: IBackdropMenuTheme;
}
export const MenuItemStyled = styled.li<MenuItemStyledProps>`
  border-bottom: 1px solid ${({componentTheme}) => componentTheme && componentTheme.itemBorder};
  width: 100%;
  max-width: 450px;
  text-transform: ${({componentTheme}) =>
    componentTheme && componentTheme.itemTextTransform ? componentTheme.itemTextTransform : 'none'};
  :hover {
    cursor: pointer;
  }
  :last-of-type {
    border-bottom: none;
  }
`;
