import axios from 'axios';
import CONFIG from '@/app/config';


export const setAuthHeader = (token) => {
  axios.defaults.headers.common['Auth-Token'] = token ? token : undefined;
}

export const loginWithToken = async (autologinToken) => {
  const url = `${CONFIG.api.appUrl}/users/sign_in`;
  const data = {
    user: {
      autologin_token: autologinToken,
    },
  };

  const response = await axios.post(url, data);
  return response.data;
};

export const login = async (value) => {
  const url = `${CONFIG.api.appUrl}/users/sign_in`;
  const { ...rest } = value;
  const data = {
    user: {
      ...rest,
    },
  };

  const response = await axios.post(url, data);
  return response.data;
};

export const getUser = async () => {
  const url = `${CONFIG.api.appUrl}/user/info`;
  const response = await axios.get(url);
  return response.data;
};

export const logout = async () => {
  const url = `${CONFIG.api.appUrl}/users/sign_out`;
  const response = await axios.delete(url);
  return response.data;
};

export const deactivateAccount = async () => {
  const url = `${CONFIG.api.appUrl}/account/deactivate`;
  const response = await axios.post(url);
  return response.data;
};

export const forgotPassword = async (value) => {
  const url = `${CONFIG.api.appUrl}/users/password`;
  const data = {
    user: {
      ...value,
    },
  };

  const response = await axios.post(url, data);
  return response.data;
};

export const updatePassword = async (values) => {
  const url = `${CONFIG.api.appUrl}/user`;
  const data = {
    user: {
      ...values,
    },
  };
  const response = await axios.put(url, data);
  return response.data;
};

export const newPassword = async (value) => {
  const url = `${CONFIG.api.appUrl}/users/password`;
  const { retype_password, ...restValues } = value;

  const data = {
    user: {
      password_confirmation: retype_password,
      ...restValues,
    },
  };
  const response = await axios.put(url, data);
  return response;
};

export const newPasswordEmail = async (value) => {
  const url = `${CONFIG.api.appUrl}/users/password`;

  const data = {
    user: {
      ...value,
    },
  };
  const response = await axios.post(url, data);
  return response.data;
};

export const cancelAccount = async () => {
  const url = `${CONFIG.api.appUrl}/account/deactivate`;
  const response = await axios.post(url);
  return response.data;
};