import { Component } from 'react';
import HeaderComponent from '@dev7/pegasus-peg-ui/Header';
import {getConfig} from '@/app/utils/general';
import {ROUTES} from '@/app//Routes';
import MenuLogged from './Logged';
import MenuNotLogged from './NotLogged';

const config = getConfig();

class Header extends Component {
  state = {
    Header: null,
  };

  componentDidMount() {
    this.importComponents();
  }

  async importComponents() {
    const header = config.LAYOUT.Header.name;
    const headerModule = await import(
      /* webpackChunkName: "dynamic-component-[request]", webpackExclude: /\/__tests__\// */ `@/app/components/${header}`
    );

    this.setState(() => ({
      Header: headerModule.default,
    }));
  }

  render() {
    const {theme, location, user} = this.props;
    const {withBackgroundImg} = theme.others;
    const hasBackground = location.pathname === ROUTES.DISCOVER.path && withBackgroundImg;

    return (
      this.state.Header && (
        <HeaderComponent hasBackground={hasBackground}>{user ? <MenuLogged /> : <MenuNotLogged />}</HeaderComponent>
      )
    );
  }
}

export default Header;
