import {combineReducers} from 'redux-immutable';
import { reducer as startupReducer } from '@/app/reducers/startup';
import { reducer as infoReducer } from '@/app/reducers/info';
import { reducer as userReducer } from '@/app/reducers/user';
import { reducer as notificationReducer } from '@/app/reducers/notification';
import { reducer as cookiesReducer } from '@/app/reducers/cookies';
import { reducer as supportReducer } from '@/app/reducers/support';
import { reducer as contentTypesReducer } from '@/app/reducers/contentTypes';
import { reducer as categoriesReducer } from '@/app/reducers/categories';
import { reducer as contentsReducer } from '@/app/reducers/contents';
import { reducer as contentReducer } from '@/app/reducers/content';
import { reducer as contentLinkReducer } from '@/app/reducers/contentLink';
import { reducer as favouritesReducer } from '@/app/reducers/favourites';
import { reducer as premiumReducer } from '@/app/reducers/premium';
import { reducer as forgotFlowReducer } from '@/app/reducers/forgotFlow';
import { reducer as registerReducer } from '@/app/reducers/register';
import {reducer as sessionInfoReducer} from '@/app/reducers/sessionInfo';

import {connectRouter} from 'connected-react-router';

export default function createRootReducer(history) {
  return (injectedReducers = {}) => {
    return combineReducers({
      startup: startupReducer,
      info: infoReducer,
      user: userReducer,
      notification: notificationReducer,
      cookies: cookiesReducer,
      support: supportReducer,
      content_types: contentTypesReducer,
      categories: categoriesReducer,
      contents: contentsReducer,
      content: contentReducer,
      contentLink: contentLinkReducer,
      favourites: favouritesReducer,
      premium: premiumReducer,
      forgotFlow: forgotFlowReducer,
      register: registerReducer,
      sessionInfo: sessionInfoReducer,
      router: connectRouter(history),
      ...injectedReducers,
    });
  };
}
