import axios from 'axios';
import CONFIG from '@/app/config';

export const sendEmailToSupport = async (value) => {
  const url = `${CONFIG.api.appUrl}/support`;

  const data = {
    contact: {
      ...value,
    },
  };

  const response = await axios.post(url, data);
  return response.data;
};