import {t} from '../utils/i18n/translate';

export const ERROR_CODES = {
  // Core Engine / Users / Update attributes
  CURRENT_PASSWORD_IS_INVALID: 202,

  // Core Engine / Users / New password
  RESET_PASSWORD_TOKEN_INVALID: 203,

  // Core Engine / Users / Reset password
  // Core Engine / Users / Update attributes
  // Core Engine / Users / New password
  PASSWORDS_NOT_MATCH: 204,
  PASSWORD_IS_INVALID: 205,

  // Core Engine / Users / Update attributes
  LANGUAGE_IS_INVALID: 207,
  LOCALIZATION_IS_INVALID: 208,
  UPDATE_EMAIL_IS_INVALID: 210,
  EMAIL_ALREADY_EXISTS: 211,
  CREDENTIALS_ALREADY_SET: 212,
  TOO_SHORT: 213,

  // Core Engine / Support
  NAME_IS_INVALID: 301,
  EMAIL_IS_INVALID: 302,
  COUNTRY_IS_INVALID: 303,
  MESSAGE_IS_INVALID: 304,
  EMAIL_DELIVERY_ERROR: 305,

  // Core Engine / Account / Deactivate by msisdn
  SUBSCRIPTION_NOT_FOUND: 601,

  // Core Engine / Account / Deactivate
  // Core Engine / Account / Deactivate by msisdn
  DEACTIVATION_FAILED: 701,
  SUBSCRIPTION_ALREADY_DEACTIVATED: 702,

  //  Vod Engine / User Events / Register
  //  Vod Engine / User Events / Update
  INVALID_EVENT_TYPE: 801,
  THIS_TYPE_OF_EVENT_CANNOT_BE_UPDATED: 802,
  COULD_NOT_FIND_EVENT_WITH_GIVEN_ID: 803,
  INVALID_MOVIE_ID: 804,
  INVALID_SEARCH_PHRASE: 811,

  // Core Engine / Users / Reset password
  USER_NOT_FOUND: 901,
  RESET_PASSWORD_BY_SMS_IS_NOT_AVAILABLE: 902,
  MESSAGE_DELIVERY_ERROR: 903,
  SMS_LIMIT_REACHED: 904,

  // register
  GIVEN_EMAIL_IS_ALREADY_TAKEN: 1100,
  GIVEN_EMAIL_ADDRESS_CANNOT_BE_EMPTY: 1101,
  GIVEN_EMAIL_ADDRESS_IS_INVALID: 1102,
  GIVEN_PASSWORD_CANNOT_BE_EMPTY: 1201,
  GIVEN_PASSWORD_IS_INVALID: 1202,
  GIVEN_PASSWORD_IS_TOO_SHORT: 1203,
  GIVEN_PASSWORD_IS_TOO_LONG: 1204,
  GIVEN_PASSWORD_CONFIRMATION_CANNOT_BE_EMPTY: 1301,
  GIVEN_PASSWORD_CONFIRMATION_IS_TOO_SHORT: 1303,
  GIVEN_PASSWORD_CONFIRMATION_IS_TOO_LONG: 1304,
  GIVEN_PASSWORD_CONFIRMATION_DOESNT_MATCH_PASSWORD: 1305,
  TERMS_AGREEMENT_IS_REQUIRED: 1401,
  QUESTIONARY_IS_REQUIRED: 1501,
  QUESTIONARY_IS_NOT_PARSABLE_JSON: 1502,
  FACEEBOOK_ID_IS_ALREADY_REGISTERED: 1600,
  FACEEBOOK_ID_IS_REQUIRED: 1601,
  FACEBOOK_SR_IS_REQUIRED: 1701,
  FACEBOOK_SR_IS_INVALID: 1702,
  SUBSCRIPTION_ALREADY_EXISTS_FOR_GIVEN_MSISDN: 2100,
  GIVEN_MSISDN_CANNOT_BE_EMPTY: 2101,
  GIVEN_MSISDN_IS_INVALID: 2102,
  GIVEN_MSISDN_IS_TOO_SHORT: 2103,
  GIVEN_MSISDN_IS_TOO_LONG: 2104,
  GIVEN_MSISDN_OPERATOR_IS_NOT_SUPPORTED: 2106,
  TOO_MANY_SUBSCRIPTION_CREATION_TRIES_FOR_GIVEN_MSISDN: 2107,
  TOO_MANY_SUBSCRIPTIONS_CREATED_FOR_GIVEN_MSISDN: 2108,
  THIS_MOBILE_OPERATOR_INTERNET_PROVIDER_IS_NOT_SUPPORTED: 2201,
  GIVEN_TOKEN_CANNOT_BE_EMPTY: 3101,
  GIVEN_TOKEN_IS_INVALID: 3102,
  GIVEN_TOKEN_IS_TOO_SHORT: 3103,
  GIVEN_TOKEN_IS_TOO_LONG: 3104,
  GIVEN_OPERATOR_CANNOT_BE_EPMTY: 4101,
  GIVEN_OPERATOR_IS_INVALID: 4102,
  USERS_HANDSET_IS_PROBABLY_TURNED_OFF: 5101,
  USER_IS_ABROAD_AND_IN_ROAMING: 5102,
  USERS_HANDSET_IS_IN_DO_NOT_DISTURB_MODE: 5103,
  USER_HAS_BLOCKED_PREMIUM_SMS_SERVICES_FOR_THIS_HANDSET_MSISDN: 5104,
  UNDEFINED_ERROR__SOMETHING_WENT_WRONG: 8000,
  PARAMETER_SESSION_ID_WAS_NOT_PROVIDED_IN_REQUEST_DATA: 8001,
  PAYMENT_STEP_IS_INVALID: 8002,
  ERROR_WHILE_CREATING_PAYMENT_SUBSCRIPTION: 8003,
  ALL_REGISTRATIONS_ARE_BLOCKED: 8004,
  PAYMENT_IS_THE_SAME: 8101,
  PAYMENT_IS_NOT_SUPPORTED: 8102,
  NO_PRICING_FOUND_FOR_THIS_USER: 8103,
  PAYMENT_METHOD_SWITCH_IS_NOT_POSSIBLE: 8104,
  // Vod Engine / Movies / Movies
  // Vod Engine / Movies / Recommended Movies
  LIMIT_PARAM_IS_INVALID: 6001,
  OFFSET_PARAM_IS_INVALID: 6002,
  SCREENS_PARAM_IS_INVALID: 6003,
  TO_WATCH_PARAM_IS_INVALID: 6004,
  ORDER_PARAM_IS_INVALID: 6005,
  GENRES_PARAM_IS_INVALID: 6006,
  ONLY_PREMIUM_PARAM_IS_INVALID: 6007,
  YEARS_PARAM_IS_INVALID: 6008,

  // Vod Engine / Movies / Buy movie
  MOVIE_CANNOT_BE_BOUGHT: 6101,
  USER_CANNOT_BUY_MOVIES: 6102,
  USER_ALREADY_BOUGHT_THIS_MOVIE: 6103,
  TRANSACTION_CANNOT_BE_COMPLETED: 6104,

  // Vod Engine / Movies / Movie
  PREMIUM_MOVIE_NOT_BOUGHT: 6201,
  UNEXPECTED_ERROR: 1000,
  GIVEN_VALUE_IS_USED: '0000',
  GIVEN_VALUE_CANNTO_BE_EMPTY: '0001',
  GIVEN_VALUE_IS_INVALID: '0002',
  GIVEN_VALUE_IS_TO_SHORT: '0003',
  GIVEN_VALUE_IS_TO_LONG: '0004',
};

export const ERROR_MESSAGES = {
  [ERROR_CODES.CURRENT_PASSWORD_IS_INVALID]: t('Current password is invalid!'),
  [ERROR_CODES.RESET_PASSWORD_TOKEN_INVALID]: t('Reset password token is invalid!'),
  [ERROR_CODES.PASSWORDS_NOT_MATCH]: t('Passwords do not match!'),
  [ERROR_CODES.PASSWORD_IS_INVALID]: t('Password is invalid!'),
  [ERROR_CODES.LANGUAGE_IS_INVALID]: t('Language is invalid!'),
  [ERROR_CODES.LOCALIZATION_IS_INVALID]: t('Location is invalid!'),
  [ERROR_CODES.TOO_SHORT]: t('Too short!'),
  [ERROR_CODES.EMAIL_IS_INVALID]: t('Email is invalid!'),
  [ERROR_CODES.NAME_IS_INVALID]: t('Name is invalid'),
  [ERROR_CODES.COUNTRY_IS_INVALID]: t('Country is invalid'),
  [ERROR_CODES.MESSAGE_IS_INVALID]: t('Message is invalid'),
  [ERROR_CODES.EMAIL_DELIVERY_ERROR]: t('Email delivery error'),
  [ERROR_CODES.UPDATE_EMAIL_IS_INVALID]: t('Email is invalid!'),
  [ERROR_CODES.EMAIL_ALREADY_EXISTS]: t('Email already exists!'),
  [ERROR_CODES.CREDENTIALS_ALREADY_SET]: t('Credentials already set!'),
  [ERROR_CODES.DEACTIVATION_FAILED]: t('Deactivation failed'),
  [ERROR_CODES.SUBSCRIPTION_NOT_FOUND]: t('Subscription not found'),
  [ERROR_CODES.SUBSCRIPTION_ALREADY_DEACTIVATED]: t('Subscription is already deactivated!'),
  [ERROR_CODES.THIS_TYPE_OF_EVENT_CANNOT_BE_UPDATED]: t('This type of event cannot be updated!'),
  [ERROR_CODES.COULD_NOT_FIND_EVENT_WITH_GIVEN_ID]: t('Could not find an event with the given id!'),
  [ERROR_CODES.USER_NOT_FOUND]: t('User not found!'),
  [ERROR_CODES.MESSAGE_DELIVERY_ERROR]: t('Message delivery error!'),
  [ERROR_CODES.OFFSET_PARAM_IS_INVALID]: t('Offset param is invalid!'),
  [ERROR_CODES.SCREENS_PARAM_IS_INVALID]: t('Screens param is invalid!'),
  [ERROR_CODES.TO_WATCH_PARAM_IS_INVALID]: t('To watch param is invalid!'),
  [ERROR_CODES.ORDER_PARAM_IS_INVALID]: t('Order param is invalid!'),
  [ERROR_CODES.GENRES_PARAM_IS_INVALID]: t('Genres param is invalid!'),
  [ERROR_CODES.ONLY_PREMIUM_PARAM_IS_INVALID]: t('Only premium param is invalid!'),
  [ERROR_CODES.YEARS_PARAM_IS_INVALID]: t('Years param is invalid!'),
  [ERROR_CODES.MOVIE_CANNOT_BE_BOUGHT]: t('Movie cannot be bought!'),
  [ERROR_CODES.USER_CANNOT_BUY_MOVIES]: t('User cannot buy movies!'),
  [ERROR_CODES.USER_ALREADY_BOUGHT_THIS_MOVIE]: t('User already bought this movie!'),
  [ERROR_CODES.TRANSACTION_CANNOT_BE_COMPLETED]: t('Transaction cannot be completed!'),
  [ERROR_CODES.PREMIUM_MOVIE_NOT_BOUGHT]: t('Premium movie not bought!'),
  [ERROR_CODES.LIMIT_PARAM_IS_INVALID]: t('Limit param is invalid'),
  [ERROR_CODES.INVALID_EVENT_TYPE]: t('Invalid event type'),
  [ERROR_CODES.INVALID_MOVIE_ID]: t('Invalid movie id'),
  [ERROR_CODES.INVALID_SEARCH_PHRASE]: t('Invalid search phrase'),
  [ERROR_CODES.SMS_LIMIT_REACHED]: t('Sms limit reached'),
  [ERROR_CODES.RESET_PASSWORD_BY_SMS_IS_NOT_AVAILABLE]: t('Reset password by sms is not available'),
  [ERROR_CODES.UNEXPECTED_ERROR]: t('Unexpected error'),
  [ERROR_CODES.PAYMENT_IS_THE_SAME]: t('Payment is the same as before, no change'),
  [ERROR_CODES.PAYMENT_IS_NOT_SUPPORTED]: t(
    'Payment is not supported by product or this landing (verify configuration)'
  ),
  [ERROR_CODES.NO_PRICING_FOUND_FOR_THIS_USER]: t(
    'Payment method is available but no pricing found for this user. verify configuration'
  ),
  [ERROR_CODES.PAYMENT_METHOD_SWITCH_IS_NOT_POSSIBLE]: t(
    'Payment method switch is not possible due to location restrictions'
  ),

  [ERROR_CODES.GIVEN_EMAIL_IS_ALREADY_TAKEN]: t('Given Email Is Already Taken'),
  [ERROR_CODES.GIVEN_EMAIL_ADDRESS_CANNOT_BE_EMPTY]: t('Given Email Address Cannot Be Empty'),
  [ERROR_CODES.GIVEN_EMAIL_ADDRESS_IS_INVALID]: t('Given Email Address Is Invalid'),
  [ERROR_CODES.GIVEN_PASSWORD_CANNOT_BE_EMPTY]: t('Given Password Cannot Be Empty'),
  [ERROR_CODES.GIVEN_PASSWORD_IS_INVALID]: t('Given Password Is Invalid'),
  [ERROR_CODES.GIVEN_PASSWORD_IS_TOO_SHORT]: t('Given Password Is Too Short'),
  [ERROR_CODES.GIVEN_PASSWORD_IS_TOO_LONG]: t('Given Password Is Too Long'),
  [ERROR_CODES.GIVEN_PASSWORD_CONFIRMATION_CANNOT_BE_EMPTY]: t('Given Password Confirmation Cannot Be Empty'),
  [ERROR_CODES.GIVEN_PASSWORD_CONFIRMATION_IS_TOO_SHORT]: t('Given Password Confirmation Is Too Short'),
  [ERROR_CODES.GIVEN_PASSWORD_CONFIRMATION_IS_TOO_LONG]: t('Given Password Confirmation Is Too Long'),
  [ERROR_CODES.GIVEN_PASSWORD_CONFIRMATION_DOESNT_MATCH_PASSWORD]: t(
    'Given Password Confirmation Doesnt Match Password'
  ),
  [ERROR_CODES.TERMS_AGREEMENT_IS_REQUIRED]: t('Terms Agreement Is Required'),
  [ERROR_CODES.QUESTIONARY_IS_REQUIRED]: t('Questionary Is Required'),
  [ERROR_CODES.QUESTIONARY_IS_NOT_PARSABLE_JSON]: t('Questionary Is Not Parsable Json'),
  [ERROR_CODES.FACEEBOOK_ID_IS_ALREADY_REGISTERED]: t('Faceebook Id Is Already Registered'),
  [ERROR_CODES.FACEEBOOK_ID_IS_REQUIRED]: t('Faceebook Id Is Required'),
  [ERROR_CODES.FACEBOOK_SR_IS_REQUIRED]: t('Facebook Sr Is Required'),
  [ERROR_CODES.FACEBOOK_SR_IS_INVALID]: t('Facebook Sr Is Invalid'),
  [ERROR_CODES.SUBSCRIPTION_ALREADY_EXISTS_FOR_GIVEN_MSISDN]: t('Subscription Already Exists For Given Msisdn'),
  [ERROR_CODES.GIVEN_MSISDN_CANNOT_BE_EMPTY]: t('Given Msisdn Cannot Be Empty'),
  [ERROR_CODES.GIVEN_MSISDN_IS_INVALID]: t('Given Msisdn Is Invalid'),
  [ERROR_CODES.GIVEN_MSISDN_IS_TOO_SHORT]: t('Given Msisdn Is Too Short'),
  [ERROR_CODES.GIVEN_MSISDN_IS_TOO_LONG]: t('Given Msisdn Is Too Long'),
  [ERROR_CODES.GIVEN_MSISDN_OPERATOR_IS_NOT_SUPPORTED]: t('Given Msisdn Operator Is Not Supported'),
  [ERROR_CODES.TOO_MANY_SUBSCRIPTION_CREATION_TRIES_FOR_GIVEN_MSISDN]: t(
    'Too Many Subscription Creation Tries For Given Msisdn'
  ),
  [ERROR_CODES.TOO_MANY_SUBSCRIPTIONS_CREATED_FOR_GIVEN_MSISDN]: t('Too Many Subscriptions Created For Given Msisdn'),
  [ERROR_CODES.THIS_MOBILE_OPERATOR_INTERNET_PROVIDER_IS_NOT_SUPPORTED]: t(
    'This Mobile Operator Internet Provider Is Not Supported'
  ),
  [ERROR_CODES.GIVEN_TOKEN_CANNOT_BE_EMPTY]: t('Given Token Cannot Be Empty'),
  [ERROR_CODES.GIVEN_TOKEN_IS_INVALID]: t('Given Token Is Invalid'),
  [ERROR_CODES.GIVEN_TOKEN_IS_TOO_SHORT]: t('Given Token Is Too Short'),
  [ERROR_CODES.GIVEN_TOKEN_IS_TOO_LONG]: t('Given Token Is Too Long'),
  [ERROR_CODES.GIVEN_OPERATOR_CANNOT_BE_EPMTY]: t('Given Operator Cannot Be Empty'),
  [ERROR_CODES.GIVEN_OPERATOR_IS_INVALID]: t('Given Operator Is Invalid'),
  [ERROR_CODES.USERS_HANDSET_IS_PROBABLY_TURNED_OFF]: t('Users Handset Is Probably Turned Off'),
  [ERROR_CODES.USER_IS_ABROAD_AND_IN_ROAMING]: t('User Is Abroad And In Roaming'),
  [ERROR_CODES.USERS_HANDSET_IS_IN_DO_NOT_DISTURB_MODE]: t('Users Handset Is In Do Not Disturb Mode'),
  [ERROR_CODES.USER_HAS_BLOCKED_PREMIUM_SMS_SERVICES_FOR_THIS_HANDSET_MSISDN]: t(
    'User Has Blocked Premium Sms Services For This Handset Msisdn'
  ),
  [ERROR_CODES.UNDEFINED_ERROR__SOMETHING_WENT_WRONG]: t('Undefined Error Something Went Wrong'),
  [ERROR_CODES.PARAMETER_SESSION_ID_WAS_NOT_PROVIDED_IN_REQUEST_DATA]: t(
    'Parameter Session Id Was Not Provided In Request Data'
  ),
  [ERROR_CODES.PAYMENT_STEP_IS_INVALID]: t('Payment Step Is Invalid'),
  [ERROR_CODES.ERROR_WHILE_CREATING_PAYMENT_SUBSCRIPTION]: t('Error while creating payment subscription'),
  [ERROR_CODES.ALL_REGISTRATIONS_ARE_BLOCKED]: t('All registrations are blocked'),
  [ERROR_CODES.GIVEN_VALUE_IS_USED]: t('Given value is used'),
  [ERROR_CODES.GIVEN_VALUE_CANNTO_BE_EMPTY]: t('Given value cannot be empty'),
  [ERROR_CODES.GIVEN_VALUE_IS_INVALID]: t('Given value is invalid'),
  [ERROR_CODES.GIVEN_VALUE_IS_TO_SHORT]: t('Given value is too short'),
  [ERROR_CODES.GIVEN_VALUE_IS_TO_LONG]: t('Given value is too long'),
};

export const ERROR_API_MESSAGES = {
  'Invalid Email, Msisdn, Msisdn code or password.': t('Invalid Email or password.'),
  'Deactivation for test account is forbidden.': t('Deactivation for test account is forbidden.'),
  'Invalid authentication token.': t('Invalid authentication token.'),
  'Change password for test account is forbidden.': t('Change password for test account is forbidden.'),
  'Internal Server Error': t('Unexpected error'),
};
