import axios from 'axios';
import CONFIG from '@/app/config';

const CONTENT_TYPES_URL = `${CONFIG.api.appUrl}/content_types`;

export const getCategories = async (id) => {
  const url = `${CONTENT_TYPES_URL}/${id}/categories`;

  const response = await axios.get(url);
  return response.data;
};
