import { createSelector } from 'reselect';


const selectors = {
  selectState: (state) => state.get('content'),
  isLoading: () => createSelector(selectors.selectState, content => content.get('isLoading')),
  getContent: () => createSelector(selectors.selectState, content => content.get('content')),
};

export default selectors;
