import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  isWaiting: false,
  step: 'create',
  session_id: null,
  redirect: null,
  isDisabled: false,
  action: 'paycard',
  pricings: [],
});

const reducer = prepareReducers(
  {
    [actions.changeRegisterStepAction]: (state, {payload}) => state.set('step', payload),
    [actions.getSessionAction]: (state) => state.set('isLoading', true),
    [actions.getSessionSuccessAction]: (state) => state.set('isLoading', false),
    [actions.getSessionFailedAction]: (state, {payload}) =>
      state.set('isLoading', false).set('step', payload).set('session_id', null),
    [actions.getPromoSessionAction]: (state) => state.set('isLoading', true),
    [actions.getPromoSessionSuccessAction]: (state) => state.set('isLoading', false),
    [actions.getPromoSessionFailedAction]: (state, {payload}) =>
      state.set('isLoading', false).set('step', payload).set('session_id', null),
    [actions.sendRegisterPaymentAction]: (state) => state.set('isLoading', true),
    [actions.sendRegisterPaymentSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('step', payload.step).set('redirect', payload.redirect),
    [actions.sendRegisterPaymentFailedAction]: (state, {payload}) => state.set('isLoading', false).set('step', payload),
    [actions.sendsendChangePricingAction]: (state) => state.set('isLoading', true),
    [actions.sendsendChangePricingSuccessAction]: (state) => state.set('isLoading', false),
    [actions.sendsendChangePricingFailedAction]: (state) => state.set('isLoading', false),
    [actions.sendRegisterPaymentSepaAction]: (state) => state.set('isLoading', true),
    [actions.sendRegisterPaymentSepaSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('isDisabled', false).set('step', payload.step),
    [actions.sendRegisterPaymentSepaFailedAction]: (state) => state.set('isLoading', false),
    [actions.sendRegisterPaymentSepaPinAction]: (state) => state.set('isDisabled', false),
    [actions.sendRegisterPaymentSepaPinSuccessAction]: (state) => state.set('isDisabled', false),
    [actions.sendRegisterPaymentSepaPinFailedAction]: (state) => state.set('isDisabled', true),
    [actions.setWaitSepaAction]: (state) => state.set('isWaiting', true),
    [actions.setWaitSepaSuccessAction]: (state) => state.set('isWaiting', false),
    [actions.setWaitSepaFailedAction]: (state) => state.set('isWaiting', false),
    [actions.sendChangePaymentMethod]: (state, {payload}) => state.set('action', payload),
    [actions.sendChangePaymentMethodSuccessAction]: (state, {payload}) => state.set('action', payload),
    [actions.sendChangePaymentMethodFailedAction]: (state, {payload}) => state.set('action', payload),
    [actions.sendRegisterEmailAction]: (state) => state.set('isLoading', true),
    [actions.sendRegisterEmailSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('step', payload.step).set('action', payload.action).set('pricings', payload.pricings),
    [actions.sendRegisterEmailFailedAction]: (state, {payload}) => state.set('isLoading', false).set('step', payload),
  },
  initialState
);

export default reducer;
