import styled from 'styled-components';
import Link from '@dev7/pegasus-peg-ui/Link';
import Paragraph from '@dev7/pegasus-peg-ui/Paragraph';
import Copyright from '@dev7/pegasus-peg-ui/Copyright';
import ScrollTop from '@dev7/pegasus-peg-ui/ScrollTop';
import {Col} from '@dev7/pegasus-peg-ui/Rwd';
import Wrapper from '@dev7/pegasus-peg-ui/Wrapper';
import media from '@/app/styles/media.styled';

export const ScrollTopStyled = styled(ScrollTop)`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: -13px;
  transform: translate(-50%);
  border-radius: 50%;
  padding: 0;
  outline: none;
  svg {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 59%;
  }
  ${media.md`
    top: -12px;
  `}
`;

export const LinkLogoStyled = styled(Link)`
  margin-bottom: 30px;
  ${media.md`
    margin-bottom: 16px;
  `}
  ${media.xl`
    margin-bottom: 24px;
  `}
`;

export const ColLeftStyled = styled(Col)`
  order: -1;
  text-align: center;
  ${media.md`
    text-align: left;
  `}
`;

export const ColCenterStyled = styled(Col)`
  text-align: center;
  ${media.md`
    order: 3;
    max-width: none;
    width: 100%;
    flex-basis: 100%;
  `}
  ${media.xl`
    order: 2;
    max-width: 66.6667%;
    width: 66.6667%;
    flex-basis: 66.6667%;
  `}
`;

export const ColRightStyled = styled(Col)`
  text-align: center;
  ${media.md`
    order: 2;
    margin-left: auto;
    text-align: right;
  `}
  ${media.xl`
    order: 3;
    margin-left: 0;
  `}
`;

export const LinkStyled = styled(Link)`
  display: block;
  margin: 0 8px 24px;
  transition: color 0.3s ease-in-out;
  ${media.md`
    display: inline-block
    margin-bottom: 6px;
  `}
  ${media.lg`
    margin: 0 12px 24px;
  `}
`;

export const CopyrightStyled = styled(Copyright)`
  margin-top: 8px;
  text-align: center;
  ${media.md`
    margin-top: 2px;
  `}
  ${media.xl`
    margin-top: 8px;
  `}
  span {
    color: inherit;
  }
`;

export const EntitiesStyled = styled(Paragraph)`
  margin: 8px 0 24px;
  text-align: center;
  ${media.md`
    margin: 8px 0 0 ;
  `}
`;

export const CardsImage = styled.div`
  width: 120px;
  height: 24px;
  margin: 0 auto;
  svg {
    display: inline-block;
    margin: 0 3px;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const WrapperStyled = styled(Wrapper)`
  ${media.xl`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -15px;
  `}
`;

export const SepaImg = styled.span`
  background: #f2f2f2;
  border-radius: 2px;
  display: inline-block;
  height: 24px;
  width: 36px;
  margin-right: 8px;
  vertical-align: top;
  padding: 1px 2px 0;
  ${media.md`
    margin-right: 0;
  `}
  ${media.lg`
    margin-right: 8px;
  `}
`;

export const SmallLinkStyled = styled(Link)`
  display: block;
  transition: color 0.3s ease-in-out;
  margin: 4px 0x;
  font-size: inherit;
  font-weight: inherit;
  ${media.lg`
    display: inline-block;
    margin: 0;
  `}
`;