import { all } from 'redux-saga/effects';

import contentTypesSaga from './contentTypes';
import categoriesSaga from './categories';
import contentsSaga from './contents';
import contentSaga from './content';
import contentLinkSaga from './contentLink';
import favouritesSaga from './favourites';
import premiumSaga from './premium';
import registerSaga from './register';
import startupSaga from './startup';
import supportSaga from './support';
import userSaga from './user';
import discoverSaga from './discover';
import sessionInfoSaga from './sessionInfo';

export default function* rootSaga() {
  yield all([
    contentTypesSaga(),
    categoriesSaga(),
    contentsSaga(),
    contentSaga(),
    contentLinkSaga(),
    favouritesSaga(),
    premiumSaga(),
    registerSaga(),
    startupSaga(),
    supportSaga(),
    userSaga(),
    discoverSaga(),
    sessionInfoSaga(),
  ]);
}