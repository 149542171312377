import {LocalStorage} from '../mechanisms/LocalStorage';

const AUTH_TOKEN_KEY = 'authToken';
const LOCALES_LANG_KEY = 'localesLang';

export function saveSessionTokens(authToken) {
  LocalStorage.setJsonItem(AUTH_TOKEN_KEY, authToken);
}

export function readSessionTokens() {
  return LocalStorage.getJsonItem(AUTH_TOKEN_KEY);
}

export function purgeSessionTokens() {
  LocalStorage.unsetItem(AUTH_TOKEN_KEY);
}

export function saveLocalesLang(lang) {
  LocalStorage.setItem(LOCALES_LANG_KEY, lang)
}

export function readLocalesLang() {
  return LocalStorage.getItem(LOCALES_LANG_KEY);
}


export const storeAuthToken = authToken => {
  LocalStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

export const getAuthToken = () => {
  return LocalStorage.getItem(AUTH_TOKEN_KEY);
};

export const setLocalesLang = lang => {
  LocalStorage.setItem(LOCALES_LANG_KEY, lang);
};

export const getLocalesLang = () => {
  return LocalStorage.getItem(LOCALES_LANG_KEY);
};
