import {ComponentType} from 'react';
import styled from 'styled-components';
import Title from '../Title';
import Paragraph from '../Paragraph';
import IItemBoxTheme from './IItemBoxTheme';

const TitleStyled = styled(Title)`
  margin: 0 0 8px;
`;
const ParagraphStyled = styled(Paragraph)`
  margin: 0;
`;

const ItemBoxStyled = styled.div``;

interface ItemBoxProps {
  title?: string;
  description?: string;
  pType?: string;
  children?: ComponentType | HTMLElement;
  componentTheme: IItemBoxTheme;
}

function ItemBox(props: ItemBoxProps) {
  const {title, description, pType = 'p2', children, componentTheme, ...rest} = props;
  return (
    <ItemBoxStyled {...rest}>
      <TitleStyled forwardedAs='h4' componentTheme={componentTheme}>
        {title}
      </TitleStyled>
      {description && (
        <ParagraphStyled componentTheme={componentTheme} pType={pType}>
          {description}
        </ParagraphStyled>
      )}
      {children}
    </ItemBoxStyled>
  );
}

export default ItemBox;
