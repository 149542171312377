import { createSelector } from 'reselect';


const selectors = {
  selectState: (state) => state.get('premium'),
  isLoading: () => createSelector(selectors.selectState, contents => contents.get('isLoading')),
  getPremium: () => createSelector(selectors.selectState, contents => contents.get('contents')),
};

export default selectors;
