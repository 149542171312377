import styled from 'styled-components';
import ItemBoxSource from '@dev7/pegasus-peg-ui/ItemBox';
import {Col, Row as RowSource} from '@dev7/pegasus-peg-ui/Rwd';
import applyStyles from '@/app/styles/applyStyles';
import media from '@/app/styles/media.styled';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.background};
  ${applyStyles({section: 'AllDone', component: 'TitleStyled'})}
`;

export const Row = styled(RowSource)`
  align-items: center;
  width: 100%;
`;

export const ItemBox = styled(ItemBoxSource)`
  margin-bottom: 24px;
`;

export const ContentCol = styled(Col)`
  order: -1;
  margin-top: 24px;
  ${media.md`
    order: 1;
    margin-top: 0;
  `}
`;

export const ImageCol = styled(Col)`
  margin-top: 24px;
  ${media.md`
    margin-top: 12px;
  `}
`;
