import IThemeBreakPoints from 'libs/peg-ui/src/components/Rwd/IGridBreakPointsTheme';
import {css} from 'styled-components';
import {ResponsiveBreakPoints} from './styles.interface';

export const mediaJs = (size: ResponsiveBreakPoints, breakepoints: IThemeBreakPoints) => {
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const breakpointWidth = breakepoints[size];
  return width >= breakpointWidth;
};

export const media = (breakepoints: IThemeBreakPoints) =>
  Object.keys(breakepoints).reduce((acc: any, label: string) => {
    acc[label] = (args: TemplateStringsArray) => {
      return css`
        @media screen and (min-width: ${breakepoints[label]}px) {
          ${css`
            ${args}
          `}
        }
      `;
    };

    return acc;
  }, {});

export const getThemeObject = (theme: any, name: any) => {
  if (!theme) throw Error(`Any theme object has been provided! ${name}`);
  if (!(Object.hasOwnProperty.call(theme, name))) {
    throw Error(`Theme doesn't have property for given component: ${name}`);
  } else {
    return theme[name];
  }
};

export const formatDuration = (duration: number) => {
  const hours = `${Math.floor(duration / 60)}h`;
  const minutes = Math.abs(duration % 60) > 0 ? `${Math.abs(duration % 60)}min` : '';
  return `${hours} ${minutes}`;
};

export const toCss = (rule: string) => rule.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
