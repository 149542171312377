import * as ReactDOM from 'react-dom';
import {WithThemeProps} from 'libs/peg-ui/src/components/HOC/withTheme';
import {Container} from '../Rwd';
import MenuList from './MenuList';
import {BackdropMenuContainer} from './BackdropMenu.styled';
import IBackdropMenuTheme from './IBackdropMenuTheme';

export enum LinkTypes {
  link = 'link',
  navlink = 'navlink',
  mail = 'mail',
  tel = 'tel',
  anchor = 'anchor',
  fake = 'fake',
}

export interface ILinks {
  id: number;
  type: LinkTypes;
  path: string;
  text: string;
  callback: Function;
}

export interface BackdropMenuProps extends WithThemeProps {
  isOpen: boolean;
  links: Array<ILinks>;
  componentTheme: IBackdropMenuTheme;
}

function BackdropMenu(props: BackdropMenuProps) {
  const {
    isOpen, links, componentTheme, ...rest
  } = props;
  return(
    isOpen
    ? ReactDOM.createPortal(
        <BackdropMenuContainer componentTheme={componentTheme} {...rest}>
          <Container>
            <MenuList links={links} componentTheme={componentTheme} />
          </Container>
        </BackdropMenuContainer>,
        document.body
      )
    : null
  )
}

export default BackdropMenu;
