import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from '@/app/reducers/startup';
import { actions as userActions } from '@/app/reducers/user';
import { infoSaga } from '@/app/sagas/info';
import { replace } from 'connected-react-router';
import { getQueryParam, getQueryParamsString } from '@/app/utils/query';
import { AUTO_LOGIN_TOKEN } from '@/app/constants/queryParams';
import { autoLoginSaga } from '@/app/sagas/user';
import { getUser } from '@/app/api/user';
import { getAuthToken } from '@/app/modules/localStorage';
import { setAuthHeader } from '@/app/api/user';
import Logger from '@/app/modules/logger';

const logger = Logger.getInstance('StartupSaga');

export function* startupSaga() {
  try {
    yield call(infoSaga);
    const user = yield call(authorize);
    if (user) {
      yield put(userActions.loginSuccessAction(user));
    }
  } catch (error) {
    logger.error(error);
  }
  yield put(actions.startupSuccessAction());
}

export function* authorize() {
  const token = yield call(getQueryParam, AUTO_LOGIN_TOKEN);
  if (token) {
    yield put(replace(getQueryParamsString({ [AUTO_LOGIN_TOKEN]: undefined })));
    return yield call(autoLoginSaga, token);
  } else {
    return yield call(restoreSession);
  }
}

export function* restoreSession() {
  const authToken = yield call(getAuthToken);

  yield call(setAuthHeader, authToken);
  return authToken ? yield call(getUser) : null;
}

export default function* watchForStartup() {
  yield takeLatest(actions.startupAction, startupSaga);
}
