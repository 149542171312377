import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions as contentTypesActions } from '@/app/reducers/contentTypes';
import { actions as notificationActions } from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import * as contentTypesApi from '@/app/api/contentTypes';
import { prepareError } from '@/app/utils/sagas';

const logger = Logger.getInstance('ContentTypesSaga');

export function* getContentTypesSaga() {
  try {
    const result = yield call(contentTypesApi.getContentTypes);
    yield put(contentTypesActions.getContentTypesSuccessAction(result));
    return result;
    
  } catch (error) {
    yield put(contentTypesActions.getContentTypesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForContentTypes() {
  yield all([
    takeLatest(contentTypesActions.getContentTypesAction, getContentTypesSaga),
  ]);
}
