import {createSelector} from 'reselect';

const selectors = {
  selectState: (state) => state.get('register'),
  isLoading: () => createSelector(selectors.selectState, (register) => register.get('isLoading')),
  isWaiting: () => createSelector(selectors.selectState, (register) => register.get('isWaiting')),
  isDisabled: () => createSelector(selectors.selectState, (register) => register.get('isDisabled')),
  getStep: () => createSelector(selectors.selectState, (register) => register.get('step')),
  getSessionId: () => createSelector(selectors.selectState, (register) => register.get('session_id')),
  getPromoSessionId: () => createSelector(selectors.selectState, register => register.get('session_id')),
  getRedirectUrl: () => createSelector(selectors.selectState, (register) => register.get('redirect')),
  getAction: () => createSelector(selectors.selectState, (register) => register.get('action')),
  getPricings: () => createSelector(selectors.selectState, (register) => register.get('pricings')),
};

export default selectors;
