import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { actions as contentsActions } from '@/app/reducers/contents';
import { actions as notificationActions } from '@/app/reducers/notification';
import { actions as premiumActions } from '@/app/reducers/premium';
import { actions as favouritesActions } from '@/app/reducers/favourites';
import Logger from '@/app/modules/logger';

import * as contentsApi from '@/app/api/contents';
import * as premiumApi from '@/app/api/premium';
import * as favouritesApi from '@/app/api/favourites';
import { prepareError } from '@/app/utils/sagas';
import { checkDevice } from '@/app/utils/checkDevice';
import { getContentTypesSaga } from './contentTypes';

const logger = Logger.getInstance('ContentsSaga');

export function* getDiscoverDataSaga() {
  try {
    yield call(getContentTypesSaga, { payload: null });

    const content_types = yield select(state => state.get('content_types').get('content_types'));
    if (content_types && content_types.length === 0) return;

    const contentTypeId = checkDevice(content_types);

    if (contentTypeId === null) return;

    const contentsResult = yield call(contentsApi.getContents, { content_type_id: contentTypeId });
    const premiumResult = yield call(premiumApi.getPremium, { limit: 5, content_type_id: contentTypeId });
    const favouritiesResult = yield call(favouritesApi.getFavourites, { content_type_id: contentTypeId });

    yield put(contentsActions.getContentsSuccessAction(contentsResult));
    yield put(premiumActions.getPremiumSuccessAction(premiumResult));
    yield put(favouritesActions.getFavouritesSuccessAction(favouritiesResult));
  } catch (error) {
    yield put(contentsActions.getContentsFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForDiscoveryData() {
  yield all([
    takeLatest(contentsActions.getDiscoverDataAction, getDiscoverDataSaga),
  ]);
}
