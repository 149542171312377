import { createSelector } from 'reselect';


const selectors = {
  selectState: (state) => state.get('favourites'),
  isContentLoading: () => createSelector(selectors.selectState, contents => contents.get('isContentLoading')),
  isLoading: () => createSelector(selectors.selectState, contents => contents.get('isLoading')),
  getFavourites: () => createSelector(selectors.selectState, contents => contents.get('contents')),
  getFavouritesTotal: () => createSelector(selectors.selectState, contents => contents.get('total')),
};

export default selectors;
