import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions as premiumActions } from '@/app/reducers/premium';
import { actions as notificationActions } from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import * as premiumApi from '@/app/api/premium';
import { prepareError } from '@/app/utils/sagas';

const logger = Logger.getInstance('PremiumSaga');

export function* getPremiumSaga({ payload }){
  try {
    const result = yield call(premiumApi.getPremium, payload);
    yield put(premiumActions.getPremiumSuccessAction(result));
  } catch (error) {
    yield put(premiumActions.getPremiumFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForPremiumGames() {
  yield all([
    takeLatest(premiumActions.getPremiumAction, getPremiumSaga),
  ]);
}
