import styled from 'styled-components';
import ViewSource from '@dev7/pegasus-peg-ui/View';
import media from '@/app/styles/media.styled';

const View = styled((props) => <ViewSource {...props} />)`
  position: relative;
  display: block;
  min-height: calc(100vh - 361px);
  padding: 80px 0 40px;
  ${media.md`
    min-height: calc(100vh - 146px);
    padding: 80px 0 40px;
  `} ${media.lg`
    min-height: calc(100vh - 165px);
          padding: 80px 0 40px;

  `};
`;

export default View;
