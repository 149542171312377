import {getDomain} from '@/app/utils/domain';

const ENV = process.env.NODE_ENV;
export const __TEST__ = ENV === 'test';
export const __DEV__ = ENV === 'development';
export const __STAGING__ = ENV === 'staging';
export const __PRODUCTION__ = ENV === 'production';

const domain = getDomain();
const CANCEL_ACCOUNT_HOURS_DEFAULT = 1; // in hours

const CONFIG = {
  title: process.env.REACT_APP_PRODUCT_TITLE,
  support_redirect: process.env.REACT_APP_SUPPORT_REDIRECT,
  cancel_account_duration: process.env.REACT_APP_CANCEL_ACCOUNT_HOURS || CANCEL_ACCOUNT_HOURS_DEFAULT,
  domain,
  routes: {
    signedIn: process.env.REACT_APP_ROUTE_SIGNED_IN || '',
  },
  api: {
    appUrl: process.env.REACT_APP_PRODUCT_API_URL,
    sessionUrl: process.env.REACT_APP_SESSION_URL,
  },
  logs: {
    browser: !__PRODUCTION__ || __TEST__,
    api: !__PRODUCTION__ || __TEST__,
  },
  dev_mode: __DEV__,
  prod_mode: __PRODUCTION__,
  refund_trial: process.env.REACT_APP_REFUND_TRIAL,
  pay_trial: process.env.REACT_APP_PAY_TRIAL,
  sepa: process.env.REACT_APP_SEPA === 'true',
  advance_cookie: process.env.REACT_APP_ADVANCE_COOKIES === 'true',
  live_agent: process.env.REACT_APP_LIVE_AGENT ? process.env.REACT_APP_LIVE_AGENT : '6o0pgevw',
  with_us_flag: !(process.env.REACT_APP_WITH_US_FLAG === 'false'),
  pp_date: process.env.REACT_APP_PP_DATE || false,
  buy_directly: process.env.REACT_APP_BUY_DIRECTLY || false
};

export default CONFIG;
