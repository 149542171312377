import {media} from '@dev7/pegasus-peg-ui/utils';
import {getConfig} from '@/app/utils/general';

const config = getConfig();

export const mediaJs = (size) => {
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const breakpointWidth = config.STYLEGUIDE.GRID_BREAKPOINTS[size];
  return width >= breakpointWidth;
};

export default media(config.STYLEGUIDE.GRID_BREAKPOINTS);
