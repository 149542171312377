import axios from 'axios';
import CONFIG from '@/app/config';
import {getUrlWithParams} from '@/app/utils/query';

const CONTENTS_URL = `${CONFIG.api.appUrl}/contents`;

export const getContents = async (params) => {
  const url = getUrlWithParams(`${CONTENTS_URL}`, params);

  const response = await axios.get(url);
  return { ...response.data, searchParams: params };
};
