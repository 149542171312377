import {prepareActions} from '@/app/utils/redux';

export const actions = prepareActions(
  {},
  ['getContents', true, true],
  ['getDiscoverData', true, true],
  ['getBrowseContents', true, true],
  ['getSearchContents', true, true],
);

export default actions;
