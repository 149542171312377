import {call, put} from 'redux-saga/effects';
import {actions} from '@/app/reducers/info';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import * as infoApi from '@/app/api/info';

const logger = Logger.getInstance('InfoSaga');

export function* infoSaga() {
  try {
    logger.debug('start');
    const info = yield call(infoApi.getInfo);
    yield put(actions.getInfoSuccessAction(info));
    return info;
  } catch (error) {
    // eslint-disable-next-line no-console
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default infoSaga;
