import styled from 'styled-components';
import ICheckmarkTheme from './ICheckmarkTheme';

interface ICheckmark {
  componentTheme: ICheckmarkTheme;
}

export const CheckmarkSquare = styled.span`
  position: relative;
  display: inline-block;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  margin: 0 5px;
  box-sizing: border-box;
`;

export const CheckmarkSquarePrimary = styled(CheckmarkSquare)<ICheckmark>`
  border: 1px solid ${({ componentTheme }) => componentTheme && componentTheme.border};
  background: ${({ componentTheme }) => componentTheme && componentTheme.background};
`;

export const CheckmarkSquareChecked = styled(CheckmarkSquare)<ICheckmark>`
  background: ${({ componentTheme }) => componentTheme && componentTheme.backgroundChecked};

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 9px;
    border: solid ${({ componentTheme }) => componentTheme && componentTheme.borderChecked};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    right: 6px;
    top: 3px;
    box-sizing: border-box;
  }
`;
