import axios from 'axios';
import CONFIG from '@/app/config';
import {getUrlWithParams} from '@/app/utils/query';

const CONTENTS_URL = `${CONFIG.api.appUrl}/contents`;

export const getFavourites = async (params) => {
  const url = getUrlWithParams(`${CONTENTS_URL}/favorites`, params);

  const response = await axios.get(url);
  return response.data;
};

export const favourites = async (id) => {
  const url = `${CONTENTS_URL}/${id}/favorite`;
  const response = await axios.post(url);

  return response.data;
};

export const unfavourites = async (id) => {
  const url = `${CONTENTS_URL}/${id}/unfavorite`;
  const response = await axios.post(url);

  return response.data;
};
