import _get from 'lodash/get';

const createPixelElement = (url, name = 'secure3ds') => {
  const pixel = document.createElement('iframe');
  pixel.className = name;
  pixel.setAttribute('src', url);
  document.body.appendChild(pixel);
};

export const init3DSScript = (response) => {
  const form_url = _get(response, 'additional_data.form_url');
  const challange_url = _get(response, 'additional_data.challange_url');
  const iframe_url = _get(response, 'additional_data.iframe_url');
  const action_url = _get(response, 'additional_data.action_url');
  const close_old_iframes = _get(response, 'additional_data.close_old_iframes');
  const msg = _get(response, 'additional_data.msg');
  const variables = [
    {challange_url: challange_url},
    {form_url: form_url},
    {iframe_url: iframe_url},
    {action_url: action_url},
  ];

  if (close_old_iframes) {
    remove3DSIframe();
  }

  if (msg) {
    alert(msg);
  }

  // if (!form_url || !challange_url || !iframe_url || !action_url) return;

  [].forEach.call(variables, function (item) {
    [].forEach.call(Object.keys(item), function (el) {
      if (item[el] !== null && Array.isArray(item[el])) {
        for (let url of item[el]) {
          el === 'challange_url' ? createPixelElement(url, 'secure3ds_challange') : createPixelElement(url);
        }
      } else {
        if (item[el] != null) {
          el === 'challange_url' ? createPixelElement(item[el], 'secure3ds_challange') : createPixelElement(item[el]);
        }
      }
    });
  });
};

export const remove3DSIframe = () => {
  const iframe = document.querySelectorAll('.secure3ds_challange, .secure3ds');
  if (iframe.length) {
    for (let i = 0; i < iframe.length; i++) {
      iframe[i].remove();
    }
  }
};

export const redirect3DS = (response) => {
  const redirect_url = _get(response, 'url');
  if(redirect_url) {
    window.location.href = redirect_url;
  }
}