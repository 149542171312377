import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isContentLoading: false,
  isLoading: false,
  isError: false,
  contents: [],
  total: null,
});

const reducer = prepareReducers(
  {
    [actions.getFavouritesAction]: (state) => state
      .set('isContentLoading', true)
      .set('isError', false),
    [actions.getFavouritesSuccessAction]: (state, {payload}) => state
      .set('isContentLoading', false)
      .set('isError', false)
      .set('contents', payload.contents)
      .set('total', payload.total),
    [actions.getFavouritesFailedAction]: (state) => state
      .set('isContentLoading', false)
      .set('isError', true),
    [actions.unfavouritesAction]: (state) => state
      .set('isLoading', true)
      .set('isError', false),
    [actions.unfavouritesSuccessAction]: (state) => state
      .set('isLoading', false)
      .set('isError', false),
    [actions.unfavouritesFailedAction]: (state) => state
      .set('isLoading', false)
      .set('isError', true),
    [actions.favouritesAction]: (state) => state
      .set('isLoading', true)
      .set('isError', false),
    [actions.favouritesSuccessAction]: (state) => state
      .set('isLoading', false)
      .set('isError', false),
    [actions.favouritesFailedAction]: (state) => state
      .set('isLoading', false)
      .set('isError', true),
  },
  initialState
);

export default reducer;
