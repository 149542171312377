import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  isError: false,
  content_types: [],
});

const reducer = prepareReducers(
  {
    [actions.getContentTypesAction]: (state) => state
      .set('isLoading', true)
      .set('isError', false),
    [actions.getContentTypesSuccessAction]: (state, {payload}) => state
      .set('isLoading', false)
      .set('isError', false)
      .set('content_types', payload.content_types),
    [actions.getContentTypesFailedAction]: (state) => state
      .set('isLoading', false)
      .set('isError', true)
  },
  initialState
);

export default reducer;
