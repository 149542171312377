import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  isError: false,
  contents: [],
  total: null,
});

const reducer = prepareReducers(
  {
    [actions.getPremiumAction]: (state) => state
      .set('isLoading', true)
      .set('isError', false),
    [actions.getPremiumSuccessAction]: (state, {payload}) => state
      .set('isLoading', false)
      .set('isError', false)
      .set('contents', payload.contents)
      .set('total', payload.total),
    [actions.getPremiumFailedAction]: (state) => state
      .set('isLoading', false)
      .set('isError', true)
  },
  initialState
);

export default reducer;
