import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  isError: false,
  categories: [],
});

const reducer = prepareReducers(
  {
    [actions.getCategoriesAction]: (state) => state
      .set('isLoading', true)
      .set('isError', false),
    [actions.getCategoriesSuccessAction]: (state, {payload}) => state
      .set('isLoading', false)
      .set('isError', false)
      .set('categories', payload.categories),
    [actions.getCategoriesFailedAction]: (state) => state
      .set('isLoading', false)
      .set('isError', true),
    [actions.getCurrentCategoriesAction]: (state) => state
      .set('isLoading', true)
      .set('isError', false),
    [actions.getCurrentCategoriesSuccessAction]: (state, {payload}) => state
      .set('isLoading', false)
      .set('isError', false)
      .set('categories', payload.categories),
    [actions.getCurrentCategoriesFailedAction]: (state) => state
      .set('isLoading', false)
      .set('isError', true)  
  },
  initialState
);

export default reducer;
