import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {withRouter} from 'react-router-dom';
import {bindActionCreators, compose} from 'redux';
import {toJS} from '@/app/utils/immutable/toJS';
import {actions as userActions, selectors as userSelectors} from '@/app/reducers/user';
import { selectors as contentLinkSelector } from '@/app/reducers/contentLink';
import {withTranslators} from '@/app/lib/lioness';
import Logged from './Logged';

const mapStateToProps = createStructuredSelector({
  user: userSelectors.getUser(),
  contentLink: contentLinkSelector.getContentLink(),
});

const mapDispatchToProps = (dispatch) => ({
  logout: bindActionCreators(userActions.logoutAction, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect, withTranslators)(toJS(Logged));
