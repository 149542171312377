import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';


export const initialState = fromJS({
  isLoading: false,
  contentLink: null,
});

const reducer = prepareReducers(
  {
    [actions.getContentLinkAction]: (state) => state
      .set('isLoading', true),
    [actions.getContentLinkSuccessAction]: (state, {payload}) => state
      .set('isLoading', false)
      .set('contentLink', payload),
    [actions.getContentLinkFailedAction]: (state) => state
      .set('isLoading', false)
  },
  initialState
);

export default reducer;
