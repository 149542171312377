import _get from 'lodash/get';
import {notificationTypes} from '@/app/constants/notification';
import {ERROR_API_MESSAGES, ERROR_CODES, ERROR_MESSAGES} from '@/app/constants/errorCodes';

export function getErrorCodeFromError(error, defaultErrorCode = ERROR_CODES.UNEXPECTED_ERROR) {
  return _get(error, 'response.data.errors[0].code', defaultErrorCode);
}

const prepareErrorObject = (message) => ({
  type: notificationTypes.DANGER,
  message,
});

export const prepareError = (error) => {
  if(typeof error === 'object') {
    if (error.response && error.response.data) {
      if (error.response.data.error) {
        return prepareErrorObject(ERROR_API_MESSAGES[error.response.data.error]);
      }
      if (error.response.data.errors && typeof error.response.data.errors[0] === 'string') {
        return prepareErrorObject(ERROR_API_MESSAGES[error.response.data.errors[0]]);
      }
      return prepareErrorObject(ERROR_MESSAGES[getErrorCodeFromError(error)]);
    }
  }

  return prepareErrorObject(ERROR_MESSAGES[ERROR_CODES.UNEXPECTED_ERROR]);
};

export const prepareSuccess = (message) => ({
  type: notificationTypes.SUCCESS,
  message,
});

export const getErrorMessageFromCode = (code = ERROR_CODES.UNEXPECTED_ERROR) => {
  const isErrorArray = Array.isArray(code);
  const codeMapped = isErrorArray ? code[0] || ERROR_CODES.UNEXPECTED_ERROR : code;
  return ERROR_MESSAGES[codeMapped];
};

export const prepareRegisterError = (errors = []) => {
  if (Array.isArray(errors)) {
    return prepareErrorObject(ERROR_MESSAGES[errors[0]]);
  } else {
    const errorCode = Object.values(errors)[0];
    if (errorCode) return prepareErrorObject(ERROR_MESSAGES[errorCode]);
    else return prepareErrorObject(ERROR_MESSAGES[ERROR_CODES.UNEXPECTED_ERROR]);
  }
};

export const checkIfGlobalError = (errors) => {
  return !errors || Array.isArray(errors);
};
