import styled from 'styled-components';
import Wrapper from '@dev7/pegasus-peg-ui/Wrapper';
import SearcherSource from '@dev7/pegasus-peg-ui/Searcher';
import HamburgerSource from '@dev7/pegasus-peg-ui/Hamburger';
import BackdropMenuSource from '@dev7/pegasus-peg-ui/BackdropMenu';
import Link from '@dev7/pegasus-peg-ui/Link';
import {ReactComponent as Logo} from '@/product/assets/svg/logo.svg';
import {ReactComponent as GamingIcon} from '@/app/assets/svg/icon_gaming.svg';
import media from '@/app/styles/media.styled';
import {getConfig} from '@/app/utils/general';
import MainMenuSource from '../MainMenu/index';
import UserMenuSource from './UserMenu/index';
import applyStyles from '@/app/styles/applyStyles';

const config = getConfig();

export const LogoStyled = styled(Logo)`
  display: block;
  width: ${config.STYLEGUIDE.others.logoWidth || '120px'};
  max-width: 100%;
  g {
    ${applyStyles({ section: 'Header', component: 'LogoSvg' })};
  }
`;

export const LogoLinkStyled = styled(Link)`
  display: block;
  width: ${config.STYLEGUIDE.others.logoWidth || '120px'};
  max-width: calc(100% - 40px - 16px);
  margin: 0 auto;
  z-index: 2;
  ${media.md`
    flex-shrink: 0;
    margin: 0;
  `}
`;

export const LoggedWrapper = styled(Wrapper)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-right: calc(16px + 40px);
  ${media.md`
    padding-right: 0;
  `}
`;

export const UserMenu = styled(UserMenuSource)`
  display: none;
  ${media.md`
    display: block;
    margin-left: 16px;
    flex-shrink: 0;
  `};
`;

export const Hamburger = styled(HamburgerSource)`
  flex-shrink: 0;
  margin-right: 16px;
  ${media.md`
    display: none;
  `}
`;

export const Searcher = styled(SearcherSource)`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 16px - 40px);
  ${media.md`
    position: relative;
    width: 40px;
    margin-left: auto;
  `}
`;

export const BackdropMenu = styled(BackdropMenuSource)`
  display: flex;
  justify-content: center;
  height: calc(100vh - 80px);
  top: 80px;
  ${media.md`
    display: none;
  `};

  ul {
    width: 100%;
    max-width: 450px;
    margin: auto;
    li {
      a,
      span {
        width: 100%;
        padding: 24px 0;
        text-align: center;
        ${applyStyles({ section: 'Header', component: 'BackdropMenuLink' })}
      }
      :nth-last-child(-n + 3) a,
      :nth-last-child(-n + 3) span {
        font-size: 18px;
        ${applyStyles({ section: 'Header', component: 'BackdropMenuSmallLink' })}
      }
      :last-of-type span {
        ${applyStyles({ section: 'Header', component: 'BackdropMenuCoinsLink' })}
      }
    }
  }
`;

export const MainMenu = styled(MainMenuSource)`
  display: none;
  ${media.md`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 32px;
    margin-right: 16px;
    z-index: 1;
  `}
  ${media.xl`
    margin-left: 40px;
  `}

  a:not(:last-child) {
    ${media.md`
      margin-right: 16px;
    `}
    ${media.xl`
      margin-right: 24px;
    `}
  }
`;

export const CoinsLink = styled(Link)`
  position: absolute;
  right: 220px;
  display: none;
  align-items: center;
  ${applyStyles({ section: 'Header', component: 'CoinsLink' })}

  ${media.lg`
    display: flex;
  `}
  &:hover {
    ${applyStyles({ section: 'Header', component: 'CoinsLinkHover' })}
  }
`;

export const CoinsGamingIcon = styled(GamingIcon)`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  vertical-align: middle;
  fill: ${props => props.theme.colors.warning};
  ${applyStyles({ section: 'Header', component: 'CoinsGamingIcon' })}
`;
