import { createSelector } from 'reselect';


const selectors = {
  selectState: (state) => state.get('content_types'),
  isLoading: () => createSelector(selectors.selectState, content_types => content_types.get('isLoading')),
  isError: () => createSelector(selectors.selectState, content_types => content_types.get('isError')),
  getContentTypes: () => createSelector(selectors.selectState, content_types => content_types.get('content_types')),
};

export default selectors;
