import styled from 'styled-components';
import DropDownSource from '@dev7/pegasus-peg-ui/DropDown';
import applyStyles from '@/app/styles/applyStyles';

import media from '@/app/styles/media.styled';

export const DropDown = styled(DropDownSource)`
  z-index: 3;
  > button {
    /* stylelint-disable-next-line no-descending-specificity */
    span {
      display: none;
      ${media.xl`
        display: inline;
      `}
      ${applyStyles({section: 'UserMenu', component: 'DropDownButtonText'})};
    }
    ${applyStyles({section: 'UserMenu', component: 'DropDownButton'})};
    :hover {
      ${applyStyles({section: 'UserMenu', component: 'DropDownButtonHover'})};
    }
  }

  > ul {
    min-width: 134px;
    width: auto;
    right: 0;
    top: 39px;
    border-top: solid 1px ${(props) => props.theme.DROPDOWN.buttonBorderColor};
    border-radius: ${(props) =>
      props.theme.DROPDOWN.listBorderRadius ? `${props.theme.DROPDOWN.listBorderRadius}` : `4px 0 4px 4px`};

    ${({theme}) =>
      media['xl'](`
        min-width: unset;
        top: auto;
        width: 100%;
        border-top: none;
        border-radius: ${theme.DROPDOWN.listBorderRadius ? `${theme.DROPDOWN.listBorderRadius}` : `0 0 4px 4px`};
    `)}

    /* stylelint-disable-next-line no-descending-specificity */
    li span {
      ${applyStyles({section: 'UserMenu', component: 'DropDownListItemText'})};
    }
    /* stylelint-disable-next-line no-descending-specificity */
    li svg {
      ${applyStyles({section: 'UserMenu', component: 'DropDownListItemSvg'})};
    }
  }
`;
