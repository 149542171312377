import {Component} from 'react';
import {getFlag} from './utils';
import {PickerStyled} from './LanguagePicker.styled';
import DropDown from '../DropDown';
import ILanguagePickerTheme from './ILanguagePickerTheme';

interface ILanguages {
  [key: string]: string;
}

interface ILanguagePickerProps {
  language: string;
  languages: ILanguages;
  onSelect: Function;
  showFlag?: boolean;
  mainBackground?: string;
  mainBackgroundHover?: string;
  withUSFlag?: boolean;
  componentTheme: ILanguagePickerTheme;
  theme: any;
}

interface ILanguagePickerState {
  language: string;
}

class LanguagePicker extends Component<ILanguagePickerProps, ILanguagePickerState> {
  constructor(props: ILanguagePickerProps) {
    super(props);

    this.state = {
      language: props.language || Object.values(props.languages)[0],
    };
  }

  static defaultProps = {
    language: 'en',
    showFlag: false,
  };

  componentDidUpdate() {
    if (this.props.language !== this.state.language) {
      this.setState({language: this.props.language});
    }
  }

  mapLanguages = (languages: ILanguages, lang: string) => {
    const {withUSFlag} = this.props;
    const result = Object.values(languages)
      .filter((country: string) => country !== lang)
      .map((language: string) => {
        return {
          name: language,
          type: 'Action',
          callback: () => this.handleSelect(language),
          icon: getFlag(withUSFlag ? language === 'en' ? 'us' : language : language),
          iconClassName: 'flag',
        };
      });
    return result;
  };

  handleSelect = (language: string) => {
    this.setState(
      () => ({language}),
      () => this.props.onSelect(language)
    );
  };

  render() {
    const {showFlag, componentTheme, languages, mainBackground, mainBackgroundHover, onSelect, withUSFlag, ...rest} = this.props;
    const {language} = this.state;
    const mappedLanguages = this.mapLanguages(languages, language);

    const Flag = getFlag(withUSFlag ? language === 'en' ? 'us' : language : language);

    return (
      <PickerStyled {...rest}>
        <DropDown
          componentTheme={componentTheme}
          buttonText={language}
          mainIconOptions={{
            width: 24,
            height: 16,
          }}
          mainIconListOptions={{
            width: 24,
            height: 16,
          }}
          itemsList={mappedLanguages}
          icon={showFlag && Flag}
          closeAfterClick={true}
          buttonColor={mainBackground}
          buttonColorHover={mainBackgroundHover}
        />
      </PickerStyled>
    );
  }
}

export default LanguagePicker;
