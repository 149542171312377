import {ComponentType} from 'react';
import Paragraph from 'libs/peg-ui/src/components/Paragraph';
import FieldError from '../FieldError';
import {CheckboxFieldStyled, CheckmarkSquare, InputStyled, LabelStyled} from './CheckboxField.styled';
import ICheckboxFieldTheme from './ICheckboxFieldTheme';

interface CheckboxFieldProps {
  id: string;
  name: string;
  error?: string | boolean;
  touched?: boolean;
  checked?: boolean;
  noValidate?: boolean;
  label: any;
  htmlFor?: string;
  children?: ComponentType | HTMLElement;
  componentTheme: ICheckboxFieldTheme;
}

function CheckboxField(props: CheckboxFieldProps) {
  const {id, name, error, touched, label, htmlFor, children, noValidate = false, componentTheme, ...rest} = props;
  return (
    <CheckboxFieldStyled {...rest}>
      <LabelStyled componentTheme={componentTheme} htmlFor={htmlFor}>
        <Paragraph pType='p3'>{label}</Paragraph>
        <InputStyled type='checkbox' id={id} name={name} {...rest} />
        <CheckmarkSquare visited={rest.checked} componentTheme={componentTheme} square />
      </LabelStyled>
      {error && touched && !noValidate && <FieldError componentTheme={componentTheme}>{error}</FieldError>}
      {children}
    </CheckboxFieldStyled>
  );
}

export default CheckboxField;
