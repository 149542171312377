import { Component } from 'react';
import styled from 'styled-components';
import ErrorComponent from '@dev7/pegasus-peg-ui/Error';
import Image from '@dev7/pegasus-peg-ui/Image';
import {Col, Container, Row} from '@dev7/pegasus-peg-ui/Rwd';
import ErrorImg from '@/product/assets/png/error.png';
import ErrorImgWebp from '@/product/assets/png/error.webp';
import imageSrc from '@/product/assets/png/image_3.png';
import imageSrcWepb from '@/product/assets/png/image_3.webp';
import ViewWrapper from '@/app/components/View';
import media from '@/app/styles/media.styled';
import CONFIG from '@/app/config';
import Sentry from '@/app/modules/sentry';
import {ROUTES} from '@/app//Routes';

export const ErrorWrapper = styled.section`
  margin: 24px 0 40px;
  ${media.md`
    margin: 40px 0;
  `}
`;

export const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${media.md`
    align-items: start;
    text-align: left;
    &:first-of-type {
      order: 2;
    }
  `}
`;

export const ImageStyled = styled(Image)`
  margin: 30px 0;
  width: 100%;
  height: auto;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  handleClick() {
    const {user, history} = this.props;
    const route = user ? ROUTES.DISCOVER.path : ROUTES.MAINPAGE.path;
    history.push(route);
  }

  render() {
    const {t} = this.props;
    if (this.state.hasError) {
      return (
        <ViewWrapper title={`${CONFIG.title} - ${t('Error')}`}>
          <ErrorWrapper>
            <Container>
              <Row>
                <ColStyled md='5'>
                  <ErrorComponent
                    icon={ErrorImg}
                    iconWebp={ErrorImgWebp}
                    title={t('Error')}
                    description={t('Unexpected error')}
                    buttonText={t('Go back')}
                    onBtnClick={() => {
                      this.handleClick();
                    }}
                  />
                </ColStyled>
                <ColStyled md='6' offset-md='1'>
                  <ImageStyled src={imageSrc} webp={imageSrcWepb} />
                </ColStyled>
              </Row>
            </Container>
          </ErrorWrapper>
        </ViewWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
