import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { actions as categoriesActions } from '@/app/reducers/categories';
import { actions as notificationActions } from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import * as categoriesApi from '@/app/api/categories';
import { prepareError } from '@/app/utils/sagas';
import { checkDevice } from '@/app/utils/checkDevice';
import { getContentTypesSaga } from './contentTypes';

const logger = Logger.getInstance('CategoriesSaga');

export function* getCategoriesSaga({ payload }) {
  try {
    const result = yield call(categoriesApi.getCategories, payload);
    yield put(categoriesActions.getCategoriesSuccessAction(result));
  } catch (error) {
    yield put(categoriesActions.getCategoriesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* getCurrentCategoriesSaga({ payload }) {
  try {
    yield call(getContentTypesSaga, { payload: null });

    const content_types = yield select(state => state.get('content_types').get('content_types'));
    if (content_types && content_types.length === 0) return;

    const contentTypeId = checkDevice(content_types);

    if (contentTypeId === null) return;
    const result = yield call(categoriesApi.getCategories, payload || contentTypeId);
    yield put(categoriesActions.getCategoriesSuccessAction(result));
  } catch (error) {
    yield put(categoriesActions.getCategoriesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForCategories() {
  yield all([
    takeLatest(categoriesActions.getCategoriesAction, getCategoriesSaga),
    takeLatest(categoriesActions.getCurrentCategoriesAction, getCurrentCategoriesSaga),
  ]);
}
