import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {actions as userActions} from '@/app/reducers/user';
import {actions as notificationActions} from '@/app/reducers/notification';
import {actions as forgotFlowActions} from '@/app/reducers/forgotFlow';
import {push} from 'connected-react-router';
import {purgeSessionTokens, storeAuthToken} from '../modules/localStorage';
import Logger from '@/app/modules/logger';
import CONFIG from '@/app/config';
import {redirectTo} from '@/app/utils/redirect/redirect';
import {successMessages} from '@/app/constants/successMessages';
import {forgotSteps} from '@/app/constants/forgotSteps';
import {prepareError, prepareSuccess} from '@/app/utils/sagas';
import * as userApi from '@/app/api/user';

const logger = Logger.getInstance('UserSaga');

export function* autoLoginSaga(autologinToken) {
  try {
    const { auth_token, ...response } = yield call(userApi.loginWithToken, autologinToken);
    yield fork(storeAuthToken, auth_token);
    yield fork(userApi.setAuthHeader, auth_token);
    yield put(userActions.loginSuccessAction(response));
    yield put(push(CONFIG.routes.signedIn));
  } catch (error) {
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* loginSaga({ payload }) {
  const { values, prevRoute } = payload;
  try {
    const { auth_token, ...response } = yield call(userApi.login, values);
    yield fork(storeAuthToken, auth_token);
    yield fork(userApi.setAuthHeader, auth_token);
    yield put(userActions.loginSuccessAction(response));
    yield put(push(prevRoute || CONFIG.routes.signedIn));
  } catch (error) {
    logger.error(error);
    yield put(userActions.loginFailedAction(error));
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* logoutSaga() {
  try {
    yield call(userApi.logout);
    yield fork(purgeSessionTokens);
    yield fork(userApi.setAuthHeader, null);
    yield put(userActions.logoutSuccessAction());
    yield put(push('/'));
  } catch (error) {
    logger.error(error);
  }
}

export function* updateCredentialsSaga({ payload }) {
  const { values } = payload;

  try {
    yield call(userApi.forgotPassword, values);
    yield put(userActions.forgotPasswordSuccessAction());
    yield put(notificationActions.addNotificationAction(prepareSuccess(successMessages.EMAIL_WAS_SENT)));
  } catch (error) {
    logger.debug(error);
    yield put(userActions.forgotPasswordFailedAction());
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* updatePasswordSaga({ payload }) {
  const { values } = payload;
  try {
    yield call(userApi.updatePassword, values);
    yield put(notificationActions.addNotificationAction(prepareSuccess(successMessages.PASSWORD_CHANGED)));
  } catch (error) {
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* newPasswordSaga({ payload }) {
  const { values, onSuccess } = payload;
  try {
    yield call(userApi.newPassword, values);
    yield put(userActions.newPasswordSuccessAction());
    yield put(notificationActions.addNotificationAction(prepareSuccess(successMessages.PASSWORD_CHANGED)));
    yield call(onSuccess);
  } catch (error) {
    logger.error(error);
    yield put(userActions.newPasswordFailedAction());
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* newPasswordEmailSaga({ payload }) {
  const { values } = payload;
  try {
    yield call(userApi.newPasswordEmail, values);
    yield put(notificationActions.addNotificationAction(prepareSuccess(successMessages.EMAIL_WAS_SENT)));
    yield put(userActions.newPasswordEmailSuccessAction());
    yield put(forgotFlowActions.changeStepAction(forgotSteps.check_email));
  } catch (error) {
    logger.error(error);
    yield put(userActions.newPasswordEmailFailedAction());
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export const STATUS = {
  SUCCESS: 'success',
  REDIRECT: 'redirect',
  SMS: 'sms',
  PROCESSING: 'processing',
  FAILURE: 'failure',
};

export function* cancelAccountSaga({ payload }) {
  const { onSuccess } = payload;
  try {
    const result = yield call(userApi.cancelAccount);
    if (result.status === STATUS.PROCESSING) {
      //TODO what should happen?!
    } else if (result.status === STATUS.SMS) {
      //TODO what should happen?!
    } else if (result.status === STATUS.REDIRECT) {
      yield call(redirectTo, result.url);
    } else if (result.status === STATUS.SUCCESS) {
      yield call(onSuccess);
      yield put(userActions.cancelAccountSuccessAction());
    }
  } catch (error) {
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
    yield put(userActions.cancelAccountFailedAction());
    // yield put(modalActions.closeModalAction());
  }
}

export default function* watchForUser() {
  yield all([
    takeLatest(userActions.loginAction, loginSaga),
    takeLatest(userActions.logoutAction, logoutSaga),
    takeLatest(userActions.newPasswordAction, newPasswordSaga),
    takeLatest(userActions.newPasswordEmailAction, newPasswordEmailSaga),
    takeLatest(userActions.updatePasswordAction, updatePasswordSaga),
    takeLatest(userActions.cancelAccountAction, cancelAccountSaga),
    takeLatest(userActions.updateCredentialsAction, updateCredentialsSaga),
  ]);
}
