import { Component } from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import _throttle from 'lodash/throttle';
import {add2Pot} from '@/app/utils/i18n/translate';
import Button from '@dev7/pegasus-peg-ui/Button';
import InputField from '@dev7/pegasus-peg-ui/Form/InputField';
import {EyeHide, EyeShow} from './UpdateCredentialsForm.styled';

const THROTTLE_TIME = 3000;

const fieldType = {
  password: 'password',
  text: 'text',
};

class UpdateCredentialsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordType: fieldType.password,
      confirmType: fieldType.password,
    };
    this.throttleSubmit = _throttle(this.handleFormSubmit, THROTTLE_TIME);
  }
  initialValues = {
    password: '',
    password_confirmation: '',
  };

  UpdateCredentialsSchema = () => {
    // Required for add translation to pot file, see bug #11482
    add2Pot('Password must contain at least 6 characters');
    add2Pot('Password must contain not more than 50 characters');
    add2Pot('Password cannot be blank');
    add2Pot('Password confirmation cannot be blank');
    add2Pot('Passwords do not match!');

    return Yup.object().shape({
      password: Yup.string()
        .min(6, 'Password must contain at least 6 characters')
        .max(50, 'Password must contain not more than 50 characters')
        .required('Password cannot be blank'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords do not match!')
        .required('Password confirmation cannot be blank'),
    });
  };

  handleFormSubmit = ({password, password_confirmation}) => {
    const {updateCredentials, user, isLoading} = this.props;
    const data = {
      values: {
        email: user.email,
        password: password,
        passwordConfirmation: password_confirmation,
      },
    };

    !isLoading && updateCredentials(data);
  };

  toggleVisibility = (name) => {
    this.setState((prev) => ({[name]: prev[name] === fieldType.password ? fieldType.text : fieldType.password}));
  };

  render() {
    const {t, isLoading} = this.props;
    const {passwordType, confirmType} = this.state;

    const EyeIconPassword = (props) =>
      passwordType === fieldType.text ? <EyeHide {...props} /> : <EyeShow {...props} />;
    const EyeIconConfirm = (props) =>
      confirmType === fieldType.text ? <EyeHide {...props} /> : <EyeShow {...props} />;

    return (
      <Formik
        initialValues={{...this.initialValues}}
        onSubmit={this.throttleSubmit}
        validationSchema={this.UpdateCredentialsSchema()}
      >
        {({errors, touched, handleSubmit, handleChange, handleBlur}) => (
          <Form onSubmit={handleSubmit}>
            <InputField
              type={passwordType}
              id='password'
              name='password'
              placeholder={t('Enter new password')}
              label={t('New password:')}
              error={errors.password && t(errors.password)}
              touched={touched.password}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <EyeIconPassword onClick={() => this.toggleVisibility('passwordType')} />
            </InputField>
            <InputField
              type={confirmType}
              id='password_confirmation'
              name='password_confirmation'
              placeholder={t('Re-type password')}
              label={t('Re-type your password')}
              error={errors.password_confirmation && t(errors.password_confirmation)}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.password_confirmation}
            >
              <EyeIconConfirm onClick={() => this.toggleVisibility('confirmType')} />
            </InputField>
            <div>
              <Button type='submit' cat='danger' isLoading={isLoading} disabled={isLoading}>
                {t('Reset password')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default UpdateCredentialsForm;
