export class LocalStorage {
  public static readonly prefix: string = '@pegasus:';

  public static isAvailable(): boolean {
    return typeof localStorage !== 'undefined';
  }

  public static getItem(key: string, fallback?: string): string | null {
    try {
      if (this.isAvailable()) {
        const value = localStorage.getItem(this.prefix + key);
        if (value !== null) return value;
      }
    } catch (e) {
      console.warn('Access to localStorage is unavailable: ', e);
    }
    return fallback !== undefined ? fallback : null;
  }

  public static getJsonItem<T = unknown>(key: string, fallback?: T): T | null {
    try {
      if (this.isAvailable()) {
        const value = localStorage.getItem(this.prefix + key);
        if (value !== null) {
          return JSON.parse(value) as T;
        }
      }
    } catch (e) {
      console.warn('Access to localStorage is unavailable: ', e);
    }
    return fallback !== undefined ? fallback : null;
  }

  public static setItem(key: string, value: string): void {
    try {
      if (this.isAvailable()) {
        localStorage.setItem(this.prefix + key, value);
      }
    } catch (e) {
      console.warn('Access to localStorage is unavailable: ', e);
    }
  }

  public static setJsonItem<T>(key: string, value: T): void {
    try {
      if (this.isAvailable()) {
        const stringifiedValue = JSON.stringify(value);
        localStorage.setItem(this.prefix + key, stringifiedValue);
      }
    } catch (e) {
      console.warn('Access to localStorage is unavailable: ', e);
    }
  }

  public static isItemSet(key: string): boolean {
    try {
      if (this.isAvailable()) {
        return localStorage.getItem(this.prefix + key) !== null;
      }
    } catch (e) {
      console.warn('Access to localStorage is unavailable: ', e);
    }
    return false;
  }

  public static unsetItem(key: string): void {
    try {
      if (this.isAvailable()) {
        localStorage.removeItem(this.prefix + key);
      }
    } catch (e) {
      console.warn('Access to localStorage is unavailable: ', e);
    }
  }
}