import {createSelector} from 'reselect';

const selectors = {
  selectState: (state) => state.get('contents'),
  isLoading: () => createSelector(selectors.selectState, contents => contents.get('isLoading')),
  getContents: () => createSelector(selectors.selectState, contents => contents && contents.get('contents')),
  getTotal: () => createSelector(selectors.selectState, contents => contents.get('total')),
  getBrowseContents: () => createSelector(selectors.selectState, contents => contents.get('browseContents')),
  getBrowseTotal: () => createSelector(selectors.selectState, contents => contents.get('browseTotal')),
  getSearchContents: () => createSelector(selectors.selectState, contents => contents.get('searchContents')),
  getSearchTotal: () => createSelector(selectors.selectState, contents => contents.get('searchTotal')),
  //TODO: add to 'watchLater' list
  //TODO: remove from 'unwatch' list
};

export default selectors;
