import axios from 'axios';
import CONFIG from '@/app/config';

const CONTENT_TYPES_URL = `${CONFIG.api.appUrl}/content_types`;

export const getContentTypes = async () => {
  const url = CONTENT_TYPES_URL;

  const response = await axios.get(url);
  return response.data;
};
