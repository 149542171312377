import { Component } from 'react';
import CONFIG from '@/app/config';
import {getConfig} from '@/app/utils/general';
import {ROUTES, ROUTES_GDPR} from '@/app/Routes';
import FooterComponent from '@dev7/pegasus-peg-ui/Footer';
import Wrapper from '@dev7/pegasus-peg-ui/Wrapper';
import Paragraph from '@dev7/pegasus-peg-ui/Paragraph';
import {Container, Row} from '@dev7/pegasus-peg-ui/Rwd';
import {
  CardsImage,
  ColCenterStyled,
  ColLeftStyled,
  ColRightStyled,
  CopyrightStyled,
  EntitiesStyled,
  LinkLogoStyled,
  LinkStyled,
  ScrollTopStyled,
  SepaImg,
  SmallLinkStyled,
  WrapperStyled,
} from './Footer.styled';
import {ReactComponent as Logo} from '@/product/assets/svg/logo.svg';
import {ReactComponent as Sepa} from '@/app/assets/svg/sepa.svg';
import {ReactComponent as Visa} from '@/app/assets/svg/visa.svg';
import {ReactComponent as Mastercard} from '@/app/assets/svg/mastercard.svg';
import {ReactComponent as Discover} from '@/app/assets/svg/discover.svg';
import {ReactComponent as Arrow} from '@/app/assets/svg/icon_arrow_top.svg';

const config = getConfig();

class Footer extends Component {
  state = {
    Footer: null,
  };

  componentDidMount() {
    this.importComponents();
  }

  async importComponents() {
    const footer = config.LAYOUT.Footer.name;
    const footerModule = await import(
      /* webpackChunkName: "dynamic-component-[request]", webpackExclude: /\/__tests__\// */ `@/app/components/${footer}`
    );

    this.setState(() => ({
      Footer: footerModule.default,
    }));
  }

  render() {
    const {t, europe, entityFull, isUS, support_email, additional_data} = this.props;
    const {desc_phone} = additional_data || {};
    const copy = CONFIG.title + '. ' + t('All rights reserved.');

    if (!this.state.Footer) return null;

    return (
      <FooterComponent>
        <ScrollTopStyled icon={Arrow} ariaLabel={t('Scroll top')} />
        <Container>
          <Row>
            <ColLeftStyled md='2'>
              <LinkLogoStyled url={ROUTES.MAINPAGE.path} ariaLabel={t('Go to homepage')}>
                <Logo />
              </LinkLogoStyled>
            </ColLeftStyled>
            <ColCenterStyled md='8'>
              <WrapperStyled>
                <LinkStyled url={ROUTES.TERMS.path} type='anchor' target='_blank'>
                  {t('Terms of Use')}
                </LinkStyled>
                <LinkStyled url={ROUTES.PRIVACY_POLICY.path} type='anchor' target='_blank'>
                  {t('Privacy policy')}
                </LinkStyled>
                {europe && (
                  <LinkStyled url={ROUTES_GDPR.COOKIES.path} type='anchor' target='_blank'>
                    {t('Cookies policy')}
                  </LinkStyled>
                )}
                <LinkStyled type={CONFIG.support_redirect ? 'anchor' : 'link'} url={ROUTES.SUPPORT.path}>
                  {t('Support')}
                </LinkStyled>
                <LinkStyled url={ROUTES.IMPRINT.path}>{t('Imprint')}</LinkStyled>
              </WrapperStyled>
              <Wrapper>
                <Paragraph pType='p3'>
                  {t('Contact us by email: ')}
                  <SmallLinkStyled type='mail' url={support_email}>
                    {support_email}
                  </SmallLinkStyled>
                  {desc_phone && (
                    <>
                      {t(' or phone: ')}
                      <SmallLinkStyled type='tel' url={desc_phone}>
                        {desc_phone}
                      </SmallLinkStyled>
                    </>
                  )}
                </Paragraph>
                <CopyrightStyled description={copy} pType='p3' />
                <EntitiesStyled pType='p5'>{entityFull}</EntitiesStyled>
              </Wrapper>
            </ColCenterStyled>
            <ColRightStyled md='2'>
              {CONFIG.sepa && (
                <SepaImg>
                  <Sepa />
                </SepaImg>
              )}
              <CardsImage>
                {isUS && (
                  <Discover />
                )}
                <Visa />
                <Mastercard />
              </CardsImage>
            </ColRightStyled>
          </Row>
        </Container>
      </FooterComponent>
    );
  }
}

export default Footer;
