import {prepareActions} from '@/app/utils/redux';


export const actions = prepareActions({},
  ['getContent', true, true],
  ['clearContent'],
  ['bookmarkContent']
);

export default actions;
