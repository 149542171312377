import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions as contentLinkActions } from '@/app/reducers/contentLink';
import { actions as notificationActions } from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import * as contentLinkApi from '@/app/api/contentLink';
import { prepareError } from '@/app/utils/sagas';

const logger = Logger.getInstance('ContentLinkSaga');

export function* getContentLinkSaga({ payload }) {
  try {
    const result = yield call(contentLinkApi.getContentLink, payload);
    yield put(contentLinkActions.getContentLinkSuccessAction(result));
  } catch (error) {
    yield put(contentLinkActions.getContentLinkFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForContentLink() {
  yield all([
    takeLatest(contentLinkActions.getContentLinkAction, getContentLinkSaga),
  ]);
}
