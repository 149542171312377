// @flow
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { actions as contentsActions } from '@/app/reducers/contents';
import { actions as notificationActions } from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import * as contentsApi from '@/app/api/contents';
import { prepareError } from '@/app/utils/sagas';
import { checkDevice } from '@/app/utils/checkDevice';
import { getContentTypesSaga } from './contentTypes';

const logger = Logger.getInstance('ContentsSaga');

// Check device and get content
export function* getContentsSaga({ payload }) {
  try {
    yield call(getContentTypesSaga, { payload: null });

    const content_types = yield select(state => state.get('content_types').get('content_types'));
    if (content_types && content_types.length === 0) return;

    const contentTypeId = checkDevice(content_types);

    if (contentTypeId === null) return;
    const result = yield call(contentsApi.getContents, { ...payload, content_type_id: contentTypeId });

    yield put(contentsActions.getContentsSuccessAction(result));
  } catch (error) {
    yield put(contentsActions.getContentsFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* getBrowseContentsSaga({ payload }) {
  try {
    const result = yield call(contentsApi.getContents, payload);
    yield put(contentsActions.getContentsSuccessAction(result));
  } catch (error) {
    yield put(contentsActions.getContentsFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* getSearchContentsSaga({ payload }) {
  try {
    yield call(getContentTypesSaga, { payload: null });

    const content_types = yield select(state => state.get('content_types').get('content_types'));
    if (content_types && content_types.length === 0) return;

    const contentTypeId = checkDevice(content_types);

    if (contentTypeId === null) return;
    const result = yield call(contentsApi.getContents, { ...payload, content_type_id: contentTypeId });
    yield put(contentsActions.getSearchContentsSuccessAction(result));
  } catch (error) {
    yield put(contentsActions.getSearchContentsFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* getContents() {
  //prettier-ignore
  yield all([
    takeLatest(contentsActions.getContentsAction, getContentsSaga),
    takeLatest(contentsActions.getBrowseContentsAction, getBrowseContentsSaga),
    takeLatest(contentsActions.getSearchContentsAction, getSearchContentsSaga),
  ]);
}
