import {Copy, Description, Text} from './Copyright.styled';
import ICopyrightTheme from './ICopyrightTheme';
import Paragraph from 'libs/peg-ui/src/components/Paragraph';

interface CopyrightProps {
  description: string;
  componentTheme: ICopyrightTheme;
}

function Copyright(props: CopyrightProps) {
  const {description, componentTheme, ...rest} = props;
  const year = new Date().getFullYear();

  return (
    <Paragraph componentTheme={componentTheme} {...rest}>
      <Copy> &copy; </Copy>
      <Text> {year} </Text>
      <Description>{description}</Description>
    </Paragraph>
  );
}

export default Copyright;
